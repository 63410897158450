import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import {
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
} from "../../MissionPlanDetails/styles";

import { closeAnyModal, getModalData } from "Slices/modals";
import { fetchDeployMissionPlan } from "Slices/missionPlans";
import { DeployedMissionList } from "../../DeployedMissionList/DeployedMissionList";
import { getCurrentSemanticMapId } from "Slices/appState";

export const DeployedMissionListModal = memo(() => {
  const [editedMissionPlan, setEditedMissionPlan] =
    useState<null | MissionAsset>(null);
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const currentFleetId = modalData?.fleetId as string;

  const semanticMapId = useSelector(getCurrentSemanticMapId);

  // Check currentFleetId existence before component rendered
  useLayoutEffect(() => {
    if (currentFleetId) return;

    toast.error("Please specify particular fleet before create new mission!");
    handleCloseModal();
  }, [currentFleetId]);

  const handleDeployPlanMissionAction = useCallback(
    (data: MissionAsset) => {
      if (!currentFleetId) return;

      dispatch(
        fetchDeployMissionPlan({
          ...data,
          currentFleetId,
        })
      );
    },
    [currentFleetId]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <MissionPlannerContainerStyled>
        <MissionPlannerContentStyled>
          <DeployedMissionList
            currentFleetId={currentFleetId}
            editedMissionPlan={editedMissionPlan}
            setEditedMissionPlan={setEditedMissionPlan}
            semanticMapId={semanticMapId}
            activeTab={"savedMission"}
            setActiveTab={null}
            deployPlanMissionAction={handleDeployPlanMissionAction}
            cancelAction={handleCloseModal}
            previousMode="Continue"
          />
        </MissionPlannerContentStyled>
      </MissionPlannerContainerStyled>
    </ModalStyled>
  );
});
