import React from "react";

import { QueueManagerTableHeaderStyled, ColumnTitleStyled } from "./styles";

export const QueueManagerTableHeader = () => (
  <QueueManagerTableHeaderStyled>
    <ColumnTitleStyled>Mission Name</ColumnTitleStyled>
    <ColumnTitleStyled>Stops</ColumnTitleStyled>
    <ColumnTitleStyled>Cycles</ColumnTitleStyled>
  </QueueManagerTableHeaderStyled>
);
