import styled from "styled-components";

export const MissionsContainerStyled = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const MissionsListContentStyled = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 24px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 1032px;
  overflow: hidden;
`;
