import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import {
  getIsVehiclesLoaded,
  getVehiclesByCurrentSemanticMap,
} from "Slices/vehicles";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import { getIsVehiclesLoading } from "Slices/vehicles/selectors";
import { AsideLoader } from "Components/common/Loader";
import { VehicleItem } from "../VehicleItem";
import { SemanticMapPicker } from "Components/common/SemanticMapPicker";

import { ReactComponent as EmptyImg } from "Components/common/assets/empty.svg";
import {
  VehiclesListStyled,
  SemanticMapPickerBodyStyled,
  VehiclesListContentStyled,
  VehiclesListEmptyImageStyled,
  VehiclesListEmptyMessageStyled,
} from "./styles";
import { MissionPlannerData } from "Slices/appState/reducers";

type VehiclesListProps = {
  setMissionPlannerData: (missionPlannerData: MissionPlannerData) => void;
  currentSemanticMap: SemanticMap | null;
};

export const VehiclesList: FC<VehiclesListProps> = memo(
  ({ setMissionPlannerData, currentSemanticMap }) => {
    const vehiclesByCurrentSemantic = useSelector(
      getVehiclesByCurrentSemanticMap
    );
    const vehicleTypes = useSelector(getVehicleTypes);
    const vehicleUpdatedData = useSelector(getVehiclesUpdateData);
    const isVehiclesLoading = useSelector(getIsVehiclesLoading);
    const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

    return (
      <VehiclesListStyled>
        <SemanticMapPickerBodyStyled>
          <SemanticMapPicker />
        </SemanticMapPickerBodyStyled>
        <VehiclesListContentStyled>
          {isVehiclesLoading && <AsideLoader />}
          {isVehiclesLoaded && (
            <>
              {vehiclesByCurrentSemantic.length === 0 && (
                <VehiclesListEmptyImageStyled>
                  <EmptyImg />
                  <VehiclesListEmptyMessageStyled>
                    No vehicles found for current zone
                  </VehiclesListEmptyMessageStyled>
                </VehiclesListEmptyImageStyled>
              )}
              {vehiclesByCurrentSemantic.map((vehicleItem) => {
                return (
                  <VehicleItem
                    key={vehicleItem.id}
                    currentSemanticMap={currentSemanticMap}
                    vehicleData={vehicleItem}
                    vehicleUpdatedData={vehicleUpdatedData}
                    setMissionPlannerData={setMissionPlannerData}
                    vehicleType={vehicleTypes.find(
                      ({ id }) => id === vehicleItem.vehicleTypeId
                    )}
                  />
                );
              })}
            </>
          )}
        </VehiclesListContentStyled>
      </VehiclesListStyled>
    );
  }
);
