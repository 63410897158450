export const sortCollectionByNumberField = (
  collection: Array<{
    [key: string]: number | string;
  }> | null,
  field: string
) => {
  if (collection) {
    return [...collection].sort(
      (prev, next) => (prev[field] as number) - (next[field] as number)
    );
  } else {
    return [];
  }
};

export const sortCollectionByName = (
  collection: Array<{
    [key: string]: string;
  }> | null
) => {
  if (collection) {
    return [...collection].sort((prev, next) =>
      prev.name.localeCompare(next.name)
    );
  } else {
    return [];
  }
};

export const sortModalItemsAlphabetically = (modalItems: Array<ModalItem>) =>
  modalItems.sort((prev, next) => prev.text.localeCompare(next.text));
