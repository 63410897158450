import React, { memo } from "react";

import { MissionsLoader } from "Pages/MissionsPage/Missions.loader";
import { MissionsController } from "Pages/MissionsPage/Missions.controller";
import { Missions } from "Components/MissionsPage/Missions";

export const MissionsPage = memo(() => {
  return (
    <MissionsLoader>
      <MissionsController />
      <Missions />
    </MissionsLoader>
  );
});
