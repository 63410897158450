import {
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchGetActiveMissions,
  getFleetIdsRelatesToVehiclePage,
  setInitialDriveModeStatuses,
  setVehiclesPageVehicles,
  updateVehicleDriveModeStatus,
  updateVehiclesPageDeployments,
} from "Slices/vehiclesPage";
import { getCurrentSemanticMapId } from "Slices/appState";
import { getVehicles } from "Slices/vehicles";
import { useFMSWebsocket } from "Utils/webSocketUtils";
import {
  VEHICLE_LIST_MISSIONS_WS,
  VEHICLE_LIST_STATE_WS,
  WS_SPINNER_LOGS,
} from "Utils/constants";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";
import {
  isDeploymentValid,
  isVehicleStateValid,
  isVehicleStatesValid,
} from "Utils";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:VehicleList`);

export const VehiclesListController = memo(() => {
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  const [isVehiclesWSConnected, setIsVehiclesWSConnected] = useState(false);
  const [isMissionWSConnected, setIsMissionWSConnected] = useState(false);

  useEffect(() => {
    if (isVehiclesWSConnected && isMissionWSConnected) {
      wsSpinnerLogs.debug(
        "isVehiclesWSConnected and isMissionWSConnected spinner off"
      );
      setIsWSConnecting?.(false);
    } else {
      wsSpinnerLogs.debug(
        "no isVehiclesWSConnected or no isMissionWSConnected spinner on"
      );
      setIsWSConnecting?.(true);
    }
  }, [isVehiclesWSConnected, isMissionWSConnected]);

  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  useEffect(() => {
    setIsVehiclesWSConnected(false);
  }, [currentSemanticMapId]);

  const fleetIdsRelatesToVehiclePage = useSelector(
    getFleetIdsRelatesToVehiclePage
  );
  const vehicles = useSelector(getVehicles);

  useEffect(() => {
    dispatch(setVehiclesPageVehicles(vehicles));
  }, [vehicles]);

  //prevents recalculations of component due to fleetIdsRelatesToVehiclePage is affected by vehicles inside selector getFleetIdsRelatesToVehiclePage
  const currentFleetIds = useMemo(() => {
    setIsMissionWSConnected(false);
    return fleetIdsRelatesToVehiclePage;
  }, [JSON.stringify(fleetIdsRelatesToVehiclePage)]);

  useEffect(() => {
    if (!currentFleetIds) return;

    dispatch(fetchGetActiveMissions(currentFleetIds));
  }, [currentFleetIds]);

  useFMSWebsocket({
    url: "/fleets/deployment_states/",
    subscriptionData: currentFleetIds,
    loggerFlag: VEHICLE_LIST_MISSIONS_WS,
    getDataForSubscribe: (subscriptionData) => ({
      fleet_ids: subscriptionData,
    }),
    getDataForUnsubscribe: (subscriptionData) => ({
      fleet_ids: subscriptionData,
    }),
    handleInitialMessage: () => {
      setIsMissionWSConnected(true);
    },
    handleMessage: (data) => {
      if (data.deployment && !isDeploymentValid(data.deployment)) {
        delete data.deployment;
      }
      const shouldIgnoreUpdate =
        !data.deployment || data.deployment?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      dispatch(updateVehiclesPageDeployments(data.deployment));
    },
  });

  useFMSWebsocket({
    url: "/vehicles/state/",
    subscriptionData: currentSemanticMapId,
    loggerFlag: VEHICLE_LIST_STATE_WS,
    getDataForSubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
      fields: ["t_drive_mode"],
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
    }),
    handleInitialMessage: (data: VehicleState[]) => {
      setIsVehiclesWSConnected(true);
      if (data && !isVehicleStatesValid(data)) return;
      const driveModeStateByVehicleId = data.reduce(
        (acc: Record<string, string>, curr) => {
          if (curr.t_drive_mode !== null)
            acc[curr.vehicle_id] = curr.t_drive_mode;

          return acc;
        },
        {}
      );
      dispatch(setInitialDriveModeStatuses(driveModeStateByVehicleId));
    },
    handleMessage: (data: VehicleState) => {
      if (data && !isVehicleStateValid(data)) return;
      dispatch(updateVehicleDriveModeStatus(data));
    },
  });

  return null;
});
