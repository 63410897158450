import React from "react";

import {
  MissionsPageGuard,
  NotForMissionOperatorGuard,
  OnlyForAdminGuard,
} from "./NavigationGuards";
import { RealTimeMapPage } from "Pages/RealTimeMap/RealTimeMap.page";
import { MissionOperatorPage } from "Pages/MissionOperator/MissionOperator.page";
import { MissionPlannerPage } from "Pages/MissionPlanner/MissionPlanner.page";
import { WelcomePage } from "Pages/Welcome/Welcome.page";
import { VehiclesListPage } from "Pages/VehiclesList/VehiclesList.page";
import { VehicleTypesListPage } from "Pages/VehicleTypesList/VehicleTypesList.page";
import { FleetsListPage } from "Pages/FleetsList/FleetsList.page";
import { UsersListPage } from "Pages/UsersList/UsersList.page";
import { MissionsPage } from "Pages/MissionsPage/Missions.page";
import { QueueManagerTable } from "Components/QueueManagerTable/QueueManagerTable";
import { LiveMissionsTable } from "Components/LiveMissionsTable/LiveMissionsTable";

export const QUEUE_MISSIONS_PATH = "/queue_missions";
export const LIVE_MISSIONS_PATH = "/live_missions";
export const MISSIONS_PATH = "/missions";

export const AuthorizedRouter = [
  {
    path: "",
    element: (
      <NotForMissionOperatorGuard>
        <RealTimeMapPage />
      </NotForMissionOperatorGuard>
    ),
  },
  {
    path: "/operator",
    element: <MissionOperatorPage />,
  },
  {
    path: MISSIONS_PATH,
    element: (
      <MissionsPageGuard>
        <MissionPlannerPage />
      </MissionsPageGuard>
    ),
  },
  {
    path: "",
    element: (
      <MissionsPageGuard>
        <MissionsPage />
      </MissionsPageGuard>
    ),
    children: [
      {
        path: LIVE_MISSIONS_PATH,
        element: <LiveMissionsTable />,
      },
      {
        path: QUEUE_MISSIONS_PATH,
        element: <QueueManagerTable />,
      },
    ],
  },
  {
    path: "/welcome",
    element: <WelcomePage />,
  },
  {
    path: "/vehicles",
    element: (
      <OnlyForAdminGuard>
        <VehiclesListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/vehicletypes",
    element: (
      <OnlyForAdminGuard>
        <VehicleTypesListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/fleets",
    element: (
      <OnlyForAdminGuard>
        <FleetsListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/people",
    element: (
      <OnlyForAdminGuard>
        <UsersListPage />
      </OnlyForAdminGuard>
    ),
  },
];
