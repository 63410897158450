import { createSlice } from "@reduxjs/toolkit";

import { RowData } from "Components/NewList/types";

type Missions = {
  missionsPageFleetId: string | null;
  missions: null | Array<Mission>;
  isUpdating: boolean;
  isMissionsUpdatesPaused: null | boolean;
  missionsUpdatesStorage: Array<Mission | never>;
};

const initialState: Missions = {
  missionsPageFleetId: null,
  missions: null,
  isUpdating: false,
  isMissionsUpdatesPaused: false,
  missionsUpdatesStorage: [],
};

export const Slice = createSlice({
  name: "missionsPage",
  initialState: initialState,
  reducers: {
    resetMissionsPageData: () => initialState,
    setMissionsPageFleetId: (state, action) => {
      state.missionsPageFleetId = action.payload;
      return state;
    },

    // when picked semantic map was changed
    resetMissions: (state) => {
      state.missions = null;

      return state;
    },
    setMissions: (state, action) => {
      state.missions = action.payload;
      return state;
    },
    reorderMissions: (state, action) => {
      // TODO: implement placing near ordered element procedure
      const reorderedMissions = action.payload
        ?.map?.((id: RowData["id"]) => {
          const foundedReorderedItem = state.missions?.find(
            (item) => item.id === id
          );
          return foundedReorderedItem;
        })
        .filter(Boolean);

      state.missions = reorderedMissions ? reorderedMissions : state.missions;
      return state;
    },
    updateMissions: (state, action) => {
      const receivedMission = action.payload;
      if (!receivedMission) return state;

      if (state.isMissionsUpdatesPaused) {
        state.missionsUpdatesStorage.push(action.payload?.data);
        return state;
      }

      const isNewMission = !state.missions?.find(
        (mission) => mission?.id === receivedMission?.id
      );

      if (isNewMission) {
        state.missions = [
          receivedMission,
          ...(state.missions?.length ? state.missions : []),
        ];
      } else {
        state.missions =
          state.missions?.map((mission) =>
            mission.id === receivedMission?.id
              ? {
                  ...mission,
                  vehicle: receivedMission?.vehicle,
                  status: receivedMission?.status,
                  passedLoops: receivedMission?.passedLoops,
                  passedStops: receivedMission?.passedStops,
                }
              : mission
          ) ?? [];
      }
      return state;
    },
    // handleDragEnd
    pauseMissionsUpdates: (state) => {
      state.isMissionsUpdatesPaused = true;
      return state;
    },
    // handleDragEnd
    continueMissionsUpdates: (state) => {
      state.isMissionsUpdatesPaused = false;
      return state;
    },
    // after ejecting all missions from the storage
    resetMissionsUpdatesStorage: (state) => {
      state.missionsUpdatesStorage = [];
      return state;
    },
  },
});

export default Slice;
