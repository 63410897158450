import React, { FC, memo, MouseEvent, useMemo } from "react";
import { useDispatch } from "react-redux";

import { getLoopsField, getStopsField } from "Components/common/TableElements";
import { TableOptions } from "Components/common/TableOptions/TableOptions";
import { FMSLogger } from "FMSLogger";
import { QUEUE_MANAGER_ACTIONS } from "Utils/constants";
import { QUEUE_MANAGER_WRAPPER } from "Components/QueueManagerTable/QueueManagerTable";
import { cancelLiveAndQueueMission } from "Slices/missionsPage/actions";
import { toggleModal } from "Slices/modals";

import {
  CellContainerStyled,
  CellButtonStyled,
  TableOptionsContainerStyled,
} from "./styles";

type MissionRowProps = {
  mission: Mission;
};

export const MissionRow: FC<MissionRowProps> = memo(({ mission }) => {
  const dispatch = useDispatch();
  const { name, passedLoops, totalLoops, passedStops, totalStops } = mission;

  const handleOpenMissionDetails = (event: MouseEvent<HTMLDivElement>) => {
    if (mission) {
      dispatch(
        toggleModal({
          type: "MISSION_DETAILS_MODAL",
          data: { missionId: mission.id },
        })
      );
      event.stopPropagation();
    }
  };

  const queueManagerActionsLogger = FMSLogger.byPrefix(QUEUE_MANAGER_ACTIONS);

  const options = useMemo(
    () => [
      {
        name: "Cancel",
        callback: (item: Mission) => {
          queueManagerActionsLogger.debug(
            `Pressed cancel mission with id:${item.id}`
          );
          dispatch(
            cancelLiveAndQueueMission({
              missionId: item.id,
              vehicleId: item.vehicle,
            })
          );
        },
      },
    ],
    []
  );

  const optionsDropdownOffset = useMemo(() => ({ top: 50, right: -10 }), []);

  return (
    <>
      <CellContainerStyled>
        <CellButtonStyled
          onClickCapture={(event) => handleOpenMissionDetails(event)}
        >
          {name}
        </CellButtonStyled>
      </CellContainerStyled>
      <CellContainerStyled>
        {getStopsField(passedStops, totalStops)}
      </CellContainerStyled>
      <CellContainerStyled>
        {getLoopsField(passedLoops, totalLoops)}
        <TableOptionsContainerStyled>
          <TableOptions
            parentElementId={QUEUE_MANAGER_WRAPPER}
            item={mission}
            options={options}
            optionsDropdownOffset={optionsDropdownOffset}
          />
        </TableOptionsContainerStyled>
      </CellContainerStyled>
    </>
  );
});
