import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";
import { AsideLoader } from "Components/common/Loader";
import {
  getIsMissionPlansLoadedForCurrentSemanticMap,
  getMissionsPlansByCurrentSemanticMap,
} from "Slices/missionPlans/selectors";
import { fetchDeleteMissionPlan } from "Slices/missionPlans";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { useMissionPlansLoader } from "Utils/loaders";
import { FMSLogger } from "FMSLogger";
import { EmptyCover } from "../EmptyCover";
import { MissionPlanItem } from "../MissionPlaItem/MissionPlanItem";
import { getCurrentSemanticMapId } from "Slices/appState";
import { MissionDataLoadingError } from "Components/common/MissionDataLoadingError";

import { MissionListContentStyled } from "../../styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionListProps = {
  isShowAssignPicker: boolean;
  setActiveTab: Dispatch<SetStateAction<string>> | null;
  cancelAction: () => void;
  setEditedMissionPlan: Dispatch<SetStateAction<MissionAsset | null>>;
  deployAction: (missionAsset: MissionAsset) => VoidFunction;
  savedMissionsDisabled: boolean;
};

export const MissionPlansList: FC<MissionListProps> = memo(
  ({
    isShowAssignPicker,
    setActiveTab,
    cancelAction,
    setEditedMissionPlan,
    deployAction,
    savedMissionsDisabled,
  }) => {
    const dispatch = useDispatch();
    const missionPlansForCurrentSemanticMap = useSelector(
      getMissionsPlansByCurrentSemanticMap
    );
    const isMissionPlansLoadedForCurrentSemanticMap = useSelector(
      getIsMissionPlansLoadedForCurrentSemanticMap
    );
    const deletePlanMissionAction = (id: string) =>
      dispatch(fetchDeleteMissionPlan(id));

    const currentSemanticMapId = useSelector(getCurrentSemanticMapId);

    const { isMissionPlansLoading, isMissionPlansLoadingError } =
      useMissionPlansLoader(currentSemanticMapId);

    return (
      <MissionListContentStyled isShowAssignPicker={isShowAssignPicker}>
        {isMissionPlansLoading && <AsideLoader />}
        {isMissionPlansLoadingError && (
          <MissionDataLoadingError errorMessage="Error loading missions" />
        )}
        {isMissionPlansLoadedForCurrentSemanticMap && (
          <>
            {missionPlansForCurrentSemanticMap.length === 0 && (
              <EmptyCover
                btnClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "new mission from empty cover"
                  );
                  setActiveTab?.("newMission");
                }}
              />
            )}
            {missionPlansForCurrentSemanticMap.map((missionPlan) => (
              <MissionPlanItem
                key={missionPlan.id}
                missionData={missionPlan}
                setEditedMissionPlan={setEditedMissionPlan}
                deletePlanMissionAction={deletePlanMissionAction}
                savedMissionsDisabled={savedMissionsDisabled}
                deployAction={deployAction(missionPlan)}
              />
            ))}
            <ButtonComponent
              clickAction={() => {
                missionDeploymentActionsLogger.debug("cancel on mission list");
                cancelAction();
              }}
              type="cancel"
              posCenter={true}
              fontSize={14}
              marginBottom={0}
            >
              Cancel
            </ButtonComponent>
          </>
        )}
      </MissionListContentStyled>
    );
  }
);
