import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  Ref,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import Map, { Source, Layer, Marker, MapRef } from "react-map-gl";
import { toast } from "react-toastify";
import mapboxgl from "mapbox-gl";

import { getStopsByCurrentSemanticMap } from "Slices/stops";
import {
  getVehiclesUpdateDataArray,
  getVehiclesUpdateData,
} from "Slices/vehiclesState";
import { getVehiclesDict } from "Slices/vehicles/selectors";
import { convertVehicleData, isCoordinatesValid } from "Utils";

import { ReactComponent as StopIcon } from "./assets/stop.svg";
import { ReactComponent as PressedStopIcon } from "./assets/pressedStop.svg";
import { ReactComponent as StopAverageIcon } from "./assets/groupStops.svg";
import { VehicleIcon } from "./components/VehicleIcon";
import { VehicleTooltip } from "./components/VehicleTooltip";
import { RouteLayer } from "./layers";
import { StopLabelStyled, StopAverageStyled, MarkerStyles } from "./styles";

import "mapbox-gl/dist/mapbox-gl.css";
import { getCurrentSemanticMap } from "Slices/semanticMaps";

const TOKEN =
  "pk.eyJ1IjoiZHJldWNlIiwiYSI6ImNsZmw5MjBjeTAydDczenJyZGNwYzNuM2oifQ.OEG4BvbK2VarceCidetCyQ";

interface MapComponentProps {
  showSatView: boolean;
  pressedStopId?: string | null;
}

const DEFAULT_SOURCE = {
  type: "FeatureCollection",
  features: [],
};

const averageStop = (stops: Array<Stop>) => {
  let lat = 0;
  let long = 0;
  stops.forEach(({ latitude, longitude }) => {
    if (latitude && longitude) {
      lat = lat + latitude;
      long = long + longitude;
    }
  });

  return {
    averageLatitude: lat === 0 ? 0 : lat / stops.length,
    averageLongitude: long == 0 ? 0 : long / stops.length,
  };
};

export const MapComponent = ({
  showSatView,
  pressedStopId,
}: MapComponentProps) => {
  const currentSemanticMap = useSelector(getCurrentSemanticMap);
  const vehiclesUpdateData = useSelector(getVehiclesUpdateDataArray);
  const vehiclesUpdateDataById = useSelector(getVehiclesUpdateData);
  const vehiclesDict = useSelector(getVehiclesDict);
  const stops: Array<Stop> = useSelector(getStopsByCurrentSemanticMap);
  const [activeTooltipVehicleId, setActiveTooltipVehicleId] = useState<
    string | null
  >(null);
  const [showStops, setShowStops] = useState<boolean>(false);
  const [routeSrc, setRouteSrc] = useState<any>(DEFAULT_SOURCE);
  const mapRef = useRef<mapboxgl.Map | null>();
  const { averageLongitude, averageLatitude } = useMemo(
    () => averageStop(stops),
    [stops]
  );

  const mapBounds = useMemo(() => {
    if (!currentSemanticMap) return;
    const { bbox } = currentSemanticMap;
    if (bbox && bbox[0]) {
      return bbox;
    }
    return;
  }, [currentSemanticMap]);

  const isStopsCoordinatesValid = useMemo(
    () =>
      stops.every((stop) => isCoordinatesValid(stop.longitude, stop.latitude)),
    [stops]
  );

  useEffect(() => {
    setTimeout(() => {
      if (mapBounds && mapRef.current) {
        mapRef.current.fitBounds(mapBounds, {
          duration: 2000,
          maxZoom: 20,
          linear: false,
          offset: [80, 0],
        });
      }
    }, 500);
  }, [mapBounds]);

  useEffect(() => {
    if (currentSemanticMap && currentSemanticMap.track) {
      const isRoutesCoordinatesValid =
        currentSemanticMap.track.features?.[0]?.geometry?.coordinates?.every(
          (coordinate: Array<string>) =>
            isCoordinatesValid(Number(coordinate[0]), Number(coordinate[1]))
        );
      if (isRoutesCoordinatesValid) {
        setRouteSrc(currentSemanticMap.track);
      } else {
        toast.error("Incorrect zone coordinates!");
      }
    }
  }, [currentSemanticMap]);

  const onMapLoad = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.on("zoom", () => {
        const currentZoom = mapRef.current?.getZoom();
        if (currentZoom && currentZoom >= 17) {
          setShowStops(true);
        }

        if (currentZoom && currentZoom < 17) {
          setShowStops(false);
        }
      });
    }
  }, [showStops, mapRef, mapRef.current]);

  const showVehicleTooltip = useCallback(
    (id: string) => {
      if (activeTooltipVehicleId && activeTooltipVehicleId === id) {
        setActiveTooltipVehicleId(null);
        return;
      }

      setActiveTooltipVehicleId(id);
    },
    [activeTooltipVehicleId, vehiclesUpdateData]
  );

  //VEHICLES
  const vehicleItems = useMemo(() => {
    if (!vehiclesUpdateData) {
      return [];
    }

    const isVehiclesCoordinatesValid = vehiclesUpdateData.every((vehicle) =>
      isCoordinatesValid(vehicle.t_long, vehicle.t_lat)
    );

    if (!isVehiclesCoordinatesValid) {
      toast.error("Incorrect vehicle coordinates!");
      return [];
    }

    const vehicleMarkers: Array<JSX.Element> = [];

    vehiclesUpdateData.forEach(
      ({
        t_long,
        t_lat,
        vehicle_id,
        t_virtual_bumper_status,
        t_web_status,
      }) => {
        const currentVehicleData = vehiclesDict[vehicle_id];
        const longitude = t_long || currentVehicleData?.longitude;
        const latitude = t_lat || currentVehicleData?.latitude;

        if (vehicle_id && latitude && longitude) {
          vehicleMarkers.push(
            <Marker
              key={vehicle_id}
              longitude={longitude}
              latitude={latitude}
              scale={5}
              anchor={"top-left"}
              offset={[-6, -3]}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                showVehicleTooltip(vehicle_id);
              }}
              style={{ ...MarkerStyles, zIndex: 2, cursor: "pointer" }}
            >
              <VehicleIcon
                vbStatus={
                  t_virtual_bumper_status || currentVehicleData.vbStatus
                }
                webstate={t_web_status || currentVehicleData.webstate}
              />
            </Marker>
          );
        }
      }
    );

    return vehicleMarkers;
  }, [vehiclesUpdateData, activeTooltipVehicleId]);

  //STOPS
  const stopItems = useMemo(() => {
    if (!isStopsCoordinatesValid && stops.length) {
      toast.error("Incorrect stop coordinates!");
      return null;
    }
    return stops.map(({ longitude, latitude, id, name }) => {
      if (!id) {
        return null;
      }

      return (
        <Marker
          key={id}
          longitude={longitude}
          latitude={latitude}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
          }}
          anchor={"center"}
          style={MarkerStyles}
        >
          <StopLabelStyled>{name}</StopLabelStyled>
          {id === pressedStopId ? <PressedStopIcon /> : <StopIcon />}
        </Marker>
      );
    });
  }, [stops, pressedStopId, isStopsCoordinatesValid]);

  //STOPS COVER
  const stopAverage = useMemo(
    () => (
      <StopAverageStyled>
        {isStopsCoordinatesValid && (
          <Marker
            longitude={averageLongitude}
            latitude={averageLatitude}
            onClick={(e) => {
              e.originalEvent.stopPropagation();
            }}
            anchor={"center"}
            offset={[0, -8]}
            style={{ ...MarkerStyles, zIndex: 3 }}
          >
            <StopLabelStyled center={true}>{stops.length}</StopLabelStyled>
            <StopAverageIcon />
          </Marker>
        )}
      </StopAverageStyled>
    ),
    [stops, isStopsCoordinatesValid]
  );

  return (
    <Map
      ref={mapRef as Ref<MapRef> | undefined}
      initialViewState={{
        bounds: mapBounds,
        zoom: 0,
        bearing: 0,
        pitch: 0,
      }}
      onLoad={onMapLoad}
      mapStyle={`mapbox://styles/mapbox/${
        showSatView ? "satellite" : "light"
      }-v9`}
      mapboxAccessToken={TOKEN}
    >
      {activeTooltipVehicleId && vehiclesUpdateDataById && (
        <VehicleTooltip
          {...convertVehicleData(
            vehiclesUpdateDataById[activeTooltipVehicleId]
          )}
          vehicleData={vehiclesDict[activeTooltipVehicleId]}
          closeAction={() => setActiveTooltipVehicleId(null)}
        />
      )}
      <Source id="routeSrc" type="geojson" data={routeSrc}>
        <Layer {...RouteLayer} />
      </Source>
      {showStops && stopItems}
      {vehicleItems}
      {!showStops && stops.length && stopAverage}
    </Map>
  );
};
