import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import {
  SecondaryNavBarItems,
  SecondaryNavigationBarStyled,
  SecondaryNavItemStyled,
  SecondaryNavTitleStyled,
} from "./styles";
import {
  LIVE_MISSIONS_PATH,
  QUEUE_MISSIONS_PATH,
} from "../../../../routers/AuthorizedRouter";

interface SecondaryLinkItemType {
  id: string;
  text: string;
  link: string;
}

const linksConfig: Array<SecondaryLinkItemType> = [
  {
    id: "live",
    text: "Live missions",
    link: LIVE_MISSIONS_PATH,
  },
  {
    id: "queue",
    text: "Queue missions",
    link: QUEUE_MISSIONS_PATH,
  },
];

export const SecondaryNavBarMissions: FC<{
  isSecondaryNavMissionsShown: boolean;
  setIsSecondaryNavMissionsShown: Dispatch<SetStateAction<boolean>>;
}> = memo(({ isSecondaryNavMissionsShown, setIsSecondaryNavMissionsShown }) => {
  const { pathname } = useLocation();

  return (
    <SecondaryNavigationBarStyled
      isShown={isSecondaryNavMissionsShown}
      onMouseEnter={() => {
        setIsSecondaryNavMissionsShown(true);
      }}
      onMouseLeave={() => {
        setIsSecondaryNavMissionsShown(false);
      }}
    >
      <SecondaryNavBarItems>
        {linksConfig.map(({ id, link, text }) => (
          <SecondaryNavItemStyled
            key={id}
            to={link}
            active={pathname === link}
            onClick={() => {
              setIsSecondaryNavMissionsShown(false);
            }}
          >
            <SecondaryNavTitleStyled>{text}</SecondaryNavTitleStyled>
          </SecondaryNavItemStyled>
        ))}
      </SecondaryNavBarItems>
    </SecondaryNavigationBarStyled>
  );
});
