import React, { FC, memo, useMemo } from "react";
import { HitchType } from "../../../../../store/types";

import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as StopStatusIcon } from "../../assets/stopStatusIcon.svg";

import {
  MissionDetailsBodyWrapperStyled,
  LoopsBodyStyled,
  CounterLoopsBodyStyled,
  CounterLoopsStyled,
  DividerLoopsCountersStyled,
  StopsBodyStyled,
  StopRowStyled,
  StopNameBodyStyled,
  StopActionsStyled,
  ProgressBarStyled,
} from "./styles";

type MissionDetailsBodyProps = {
  mission: QueueManagerMission;
};

export const MissionDetailsBody: FC<MissionDetailsBodyProps> = memo(
  ({ mission }) => {
    const formattedLoopsStops = useMemo(() => {
      const preparedLoopsStops = [];
      // Infinite loop case
      if (Number(mission.totalLoops) === -1) {
        preparedLoopsStops.push(mission.stops);
      } else {
        for (let i = 0; i < Number(mission.totalLoops); i++) {
          preparedLoopsStops.push([...mission.stops]);
        }
      }
      return preparedLoopsStops;
    }, [mission]);

    const compileActionName = (duration: number, hitchType: HitchType) => {
      const preparedDuration =
        duration < 0 ? "Manual Confirm" : `Wait ${duration} sec`;
      const preparedHitchType = hitchType === HitchType.UNHITCH && "Unhitch";

      return [preparedDuration, preparedHitchType || null].join(
        preparedHitchType ? ", " : ""
      );
    };

    // TODO temporary functionality
    const isStopReached = (currentLoop: number, currentStop: number) =>
      currentLoop < Number(mission.passedLoops) ||
      (currentLoop === Number(mission.passedLoops) &&
        currentStop <= Number(mission.passedStops));
    return (
      <MissionDetailsBodyWrapperStyled>
        {formattedLoopsStops.map((loopStops, loopIndex) => (
          <LoopsBodyStyled key={loopIndex}>
            <CounterLoopsBodyStyled>
              Cycle:
              <CounterLoopsStyled>{loopIndex + 1}</CounterLoopsStyled>
              <DividerLoopsCountersStyled>
                <RectangleIcon />
              </DividerLoopsCountersStyled>
              <CounterLoopsStyled>{mission.totalLoops}</CounterLoopsStyled>
            </CounterLoopsBodyStyled>
            <StopsBodyStyled>
              {loopStops.map((stop, stopIndex) => (
                <>
                  <StopRowStyled key={stop.id}>
                    <StopNameBodyStyled
                      isStopReached={isStopReached(
                        loopIndex + 1,
                        stopIndex + 1
                      )}
                    >
                      <StopStatusIcon />
                      {stop.name}
                    </StopNameBodyStyled>
                    <StopActionsStyled>
                      {compileActionName(stop.duration, stop.hitchType)}
                    </StopActionsStyled>
                  </StopRowStyled>
                  {stopIndex + 1 < Number(mission.totalStops) && (
                    <ProgressBarStyled
                      isStopReached={isStopReached(
                        loopIndex + 1,
                        stopIndex + 1
                      )}
                    />
                  )}
                </>
              ))}
            </StopsBodyStyled>
          </LoopsBodyStyled>
        ))}
      </MissionDetailsBodyWrapperStyled>
    );
  }
);
