import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal, getModalData } from "Slices/modals";
import { getLiveQueueDeploymentsById } from "Slices/liveQueueDeploymentsPage";
import { getVehicleById } from "Slices/vehicles";
import { getVehicleTypeById } from "Slices/vehicleTypes";
import { MissionDataLoadingError } from "Components/common/MissionDataLoadingError";
import { DeploymentDetailsHeader } from "./components/DeploymentDetailsHeader/DeploymentDetailsHeader";
import { DeploymentDetailsBody } from "./components/DeploymentDetailsBody/DeploymentDetailsBody";
import { getMissionPlansByCurrentSemanticMap } from "Slices/missionPlans/selectors";
import { useMissionPlansLoader } from "Utils/loaders";
import { getCurrentSemanticMapId } from "Slices/appState";
import { AsideLoader } from "Components/common/Loader/Loader";
import { fetchGetMissionPlanById } from "Slices/missionPlans";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "Components/common/ModalComponent/styles";
import { MissionDetailsModalWrapperStyled } from "./styles";

export const MissionDetailsModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const currentDeployment = useSelector(
    getLiveQueueDeploymentsById(modalData?.deploymentId as string | undefined)
  );
  const currentVehicle = useSelector(
    getVehicleById(currentDeployment?.vehicleId)
  );
  const currentVehicleType = useSelector(
    getVehicleTypeById(currentVehicle?.vehicleTypeId)
  );
  const missionPlansByCurrentSemanticMap = useSelector(
    getMissionPlansByCurrentSemanticMap
  );
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const { isMissionPlansLoading, isMissionPlansLoadingError } =
    useMissionPlansLoader(currentSemanticMapId);

  const [missionPlan, setMissionPlan] = useState<MissionPlan | null>(null);

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  useEffect(() => {
    const getMissionPlan = async () => {
      const data = await fetchGetMissionPlanById(
        currentDeployment?.missionPlanId
      );
      setMissionPlan(data);
    };

    const isDeploymentOnFleet = !currentDeployment?.vehicleDeployment;
    if (isDeploymentOnFleet) {
      getMissionPlan();
    }
  }, [currentDeployment]);

  const deploymentActions = useMemo(() => {
    if (!currentDeployment) return null;

    if (currentDeployment.vehicleDeployment) {
      return currentDeployment.vehicleDeployment.plan.actions;
    } else if (missionPlan) {
      return missionPlan?.plan.actions;
    } else return null;
  }, [currentDeployment, missionPlansByCurrentSemanticMap, missionPlan]);

  const isShowMissionDataLoadingError =
    !currentDeployment ||
    isMissionPlansLoadingError ||
    (!isMissionPlansLoading && !deploymentActions);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <MissionDetailsModalWrapperStyled>
        {isMissionPlansLoading && <AsideLoader />}
        {isShowMissionDataLoadingError ? (
          <MissionDataLoadingError errorMessage="Error loading mission data" />
        ) : (
          <>
            <DeploymentDetailsHeader
              deployment={currentDeployment}
              vehicle={currentVehicle}
              vehicleType={currentVehicleType}
              handleCloseAction={handleCloseAction}
            />
            {deploymentActions && (
              <DeploymentDetailsBody
                totalLoops={
                  currentDeployment.vehicleDeployment?.totalLoops ??
                  currentDeployment.loops
                }
                passedLoops={
                  currentDeployment.vehicleDeployment?.passedLoops || 0
                }
                passedActions={
                  currentDeployment.vehicleDeployment?.passedActions || 0
                }
                actions={deploymentActions}
              />
            )}
          </>
        )}
      </MissionDetailsModalWrapperStyled>
    </ModalStyled>
  );
});
