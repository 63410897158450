import React from "react";

import { ReactComponent as PolygonIcon } from "../assets/polygonIcon.svg";
import { ReactComponent as RectangleIcon } from "../assets/rectangleIcon.svg";
import { TableFieldBodyStyled } from "Components/NewList/styles";
import {
  CounterBodyStyled,
  CounterStopsStyled,
  CounterLoopsStyled,
  DividerStopsCountersStyled,
  DividerLoopsCountersStyled,
} from "./styles";

export const getStopsField = (passedStops: string, totalStops: string) => {
  const zeroPassedStops = Number(passedStops) === 0;

  return (
    <TableFieldBodyStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled zeroCounter={zeroPassedStops}>
          {passedStops}
        </CounterStopsStyled>
      </CounterBodyStyled>
      <DividerStopsCountersStyled>Out of</DividerStopsCountersStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled>{totalStops}</CounterStopsStyled>
      </CounterBodyStyled>
    </TableFieldBodyStyled>
  );
};

export const getLoopsField = (passedLoops: string, totalLoops: string) => {
  const isInfinity = Number(totalLoops) === -1;
  const zeroPassedLoops = Number(passedLoops) === 0;

  return isInfinity ? (
    <CounterLoopsStyled zeroCounter={zeroPassedLoops} isInfinity={isInfinity}>
      {passedLoops}
    </CounterLoopsStyled>
  ) : (
    <TableFieldBodyStyled>
      <CounterLoopsStyled zeroCounter={zeroPassedLoops}>
        {passedLoops}
      </CounterLoopsStyled>
      <DividerLoopsCountersStyled>
        <RectangleIcon />
      </DividerLoopsCountersStyled>
      <CounterLoopsStyled>{totalLoops}</CounterLoopsStyled>
    </TableFieldBodyStyled>
  );
};
