import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

import { setVehiclesPageDeployments } from ".";
import { DeploymentsValidator } from "Slices/validationTypes";

export const fetchGetActiveMissions = createAsyncThunk<void, Array<string>>(
  "missionQueue",
  async (currentFleetIds: string[], thunkAPI) => {
    try {
      const allLiveDeployments: Deployment[] = [];

      const queryStatuses = "&statuses=new,sent,in_progress,paused";

      for (const currentFleetId of currentFleetIds) {
        const queryParameters = `?fleetId=${currentFleetId}${queryStatuses}`;
        const response = await API.get(`live_deployments/${queryParameters}`);
        const parsedLiveDeployments = DeploymentsValidator.parse(
          response.data.results
        );
        allLiveDeployments.concat(parsedLiveDeployments);
      }

      thunkAPI.dispatch(setVehiclesPageDeployments(allLiveDeployments));
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
