import { configureStore } from "@reduxjs/toolkit";

import appState from "Slices/appState";
import fleets from "Slices/fleets";
import companies from "Slices/companies";
import vehicleTypes from "Slices/vehicleTypes";
import vehicles from "Slices/vehicles";
import semanticMaps from "Slices/semanticMaps";
import users from "Slices/users";
import stops from "Slices/stops";
import modals from "Slices/modals";
import currentUser from "Slices/currentUser";
import roles from "Slices/roles";
import vehiclesState from "Slices/vehiclesState";
import missionPlanner from "Slices/missionPlanner";
import vehiclesPage from "Slices/vehiclesPage";
import operatorStation from "Slices/operatorStation";
import operatorMissionAssets from "Slices/operatorMissionAssets";
import operatorData from "Slices/operatorData";
import warning from "Slices/warning";
import missionPlans from "Slices/missionPlans";
import flags from "Slices/flags";
import missionsPage from "Slices/missionsPage";

export const store = configureStore({
  reducer: {
    appState,
    companies,
    fleets,
    flags,
    users,
    missionPlans,
    vehicles,
    vehicleTypes,
    semanticMaps,
    stops,
    modals,
    currentUser,
    roles,
    vehiclesState,
    missionPlanner,
    vehiclesPage,
    operatorStation,
    operatorMissionAssets,
    operatorData,
    warning,
    missionsPage,
  },
});

export type RootState = ReturnType<typeof store.getState>;
