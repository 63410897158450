import { createSlice } from "@reduxjs/toolkit";

import { RowData } from "Components/NewList/types";

type LiveQueueDeploymentsPage = {
  liveQueueDeploymentsPageFleetId: string | null;
  liveQueueDeployments: null | Array<Deployment>;
  isLiveQueueDeploymentsUpdatesPaused: null | boolean;
  liveQueueDeploymentsUpdatesStorage: Array<Deployment | never>;
  isLiveQueueDeploymentsUpdating: boolean;
};

const initialState: LiveQueueDeploymentsPage = {
  liveQueueDeploymentsPageFleetId: null,
  liveQueueDeployments: null,
  isLiveQueueDeploymentsUpdatesPaused: false,
  liveQueueDeploymentsUpdatesStorage: [],
  isLiveQueueDeploymentsUpdating: false,
};

export const Slice = createSlice({
  name: "liveQueueDeploymentsPage",
  initialState: initialState,
  reducers: {
    resetLiveQueueDeploymentsPageData: () => initialState,
    setIsLiveQueueDeploymentsUpdating: (state, action) => {
      state.isLiveQueueDeploymentsUpdating = action.payload;
    },
    setLiveQueueDeploymentsPageFleetId: (state, action) => {
      state.liveQueueDeploymentsPageFleetId = action.payload;
      return state;
    },

    // when picked semantic map was changed
    resetLiveQueueDeployments: (state) => {
      state.liveQueueDeployments = null;

      return state;
    },
    setLiveQueueDeployments: (state, action) => {
      state.liveQueueDeployments = action.payload;
      return state;
    },
    reorderLiveQueueDeployments: (state, action) => {
      // TODO: implement placing near ordered element procedure
      const reorderedLiveQueueDeployments = action.payload
        ?.map?.((id: RowData["id"]) => {
          const foundedReorderedItem = state.liveQueueDeployments?.find(
            (item) => item.id === id
          );
          return foundedReorderedItem;
        })
        .filter(Boolean);

      state.liveQueueDeployments = reorderedLiveQueueDeployments
        ? reorderedLiveQueueDeployments
        : state.liveQueueDeployments;
      return state;
    },
    updateLiveQueueDeployments: (state, action) => {
      const receivedLiveQueueDeployment = action.payload;
      if (!receivedLiveQueueDeployment) return state;

      if (state.isLiveQueueDeploymentsUpdatesPaused) {
        state.liveQueueDeploymentsUpdatesStorage.push(action.payload?.data);
        return state;
      }

      const isNewLiveQueueDeployment = !state.liveQueueDeployments?.find(
        (liveQueueDeployment) =>
          liveQueueDeployment?.id === receivedLiveQueueDeployment?.id
      );

      if (isNewLiveQueueDeployment) {
        state.liveQueueDeployments = [
          receivedLiveQueueDeployment,
          ...(state.liveQueueDeployments?.length
            ? state.liveQueueDeployments
            : []),
        ];
      } else {
        state.liveQueueDeployments =
          state.liveQueueDeployments?.map((liveQueueDeployment) =>
            liveQueueDeployment.id === receivedLiveQueueDeployment?.id
              ? receivedLiveQueueDeployment
              : liveQueueDeployment
          ) ?? [];
      }
      return state;
    },
    // handleDragEnd
    pauseLiveQueueDeploymentsUpdates: (state) => {
      state.isLiveQueueDeploymentsUpdatesPaused = true;
      return state;
    },
    // handleDragEnd
    continueLiveQueueDeploymentsUpdates: (state) => {
      state.isLiveQueueDeploymentsUpdatesPaused = false;
      return state;
    },
    // after ejecting all missions from the storage
    resetLiveQueueDeploymentsUpdatesStorage: (state) => {
      state.liveQueueDeploymentsUpdatesStorage = [];
      return state;
    },
  },
});

export default Slice;
