import React, { FC, memo, MouseEvent, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  getLoopsField,
  getActionsField,
} from "Components/common/TableElements";
import { TableOptions } from "Components/common/TableOptions/TableOptions";
import { FMSLogger } from "FMSLogger";
import { QUEUE_MANAGER_ACTIONS } from "Utils/constants";
import { QUEUE_MANAGER_WRAPPER } from "Components/QueueDeploymentsTable/QueueDeploymentsTable";
import { cancelLiveQueueDeployment } from "Slices/liveQueueDeploymentsPage/actions";
import { toggleModal } from "Slices/modals";

import {
  CellContainerStyled,
  CellButtonStyled,
  TableOptionsContainerStyled,
} from "./styles";

type DeploymentRowProps = {
  deployment: Deployment;
};

type DeploymentOptionsItemType = {
  id: string;
  name: string;
};

export const DeploymentRow: FC<DeploymentRowProps> = memo(({ deployment }) => {
  const dispatch = useDispatch();
  const {
    name,
    vehicleDeployment,
    loops: totalLoops,
    actions: totalActions,
  } = deployment;
  const { passedLoops = 0, passedActions = 0 } = vehicleDeployment || {};

  const handleOpenDeploymentDetails = (event: MouseEvent<HTMLDivElement>) => {
    if (deployment) {
      dispatch(
        toggleModal({
          type: "DEPLOYMENT_DETAILS_MODAL",
          data: { deploymentId: deployment.id },
        })
      );
      event.stopPropagation();
    }
  };

  const queueManagerActionsLogger = FMSLogger.byPrefix(QUEUE_MANAGER_ACTIONS);

  const options = useMemo(
    () => [
      {
        name: "Cancel",
        //why omit plan
        callback: (item: DeploymentOptionsItemType) => {
          queueManagerActionsLogger.debug(
            `Pressed cancel deployment with id:${item.id}`
          );
          dispatch(
            cancelLiveQueueDeployment({
              deploymentName: item.name,
              deploymentId: item.id,
            })
          );
        },
      },
    ],
    []
  );

  const optionsDropdownOffset = useMemo(() => ({ top: 50, right: -10 }), []);
  const deploymentOptionsItem: DeploymentOptionsItemType = useMemo(
    () => ({
      id: deployment.id,
      name: deployment.name,
    }),
    [deployment]
  );

  return (
    <>
      <CellContainerStyled>
        <CellButtonStyled
          onClickCapture={(event) => handleOpenDeploymentDetails(event)}
        >
          {name}
        </CellButtonStyled>
      </CellContainerStyled>
      <CellContainerStyled>
        {getActionsField(passedActions, totalActions)}
      </CellContainerStyled>
      <CellContainerStyled>
        {getLoopsField(passedLoops, totalLoops)}
        <TableOptionsContainerStyled>
          <TableOptions
            parentElementId={QUEUE_MANAGER_WRAPPER}
            item={deploymentOptionsItem}
            options={options}
            optionsDropdownOffset={optionsDropdownOffset}
          />
        </TableOptionsContainerStyled>
      </CellContainerStyled>
    </>
  );
});
