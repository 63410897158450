import styled from "styled-components";

export const MissionDetailsHeaderWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid #e9e9e9;
`;

export const MissionDetailBodyStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #606060;
  font-size: 14px;
  font-weight: 300;
`;

export const MissionNameStyled = styled.div`
  height: 24px;
  color: #172a3f;
  font-size: 20px;
  line-height: 120%;
  font-weight: 400;
`;

export const StatusBodyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
`;

export const StatusNameStyled = styled.div`
  color: #1b1b1b;
  font-size: 14px;
  line-height: 140%;
`;

export const RectangleIconBodyStyled = styled.div`
  display: flex;
  padding: 0 4px;
`;

export const AdditionalMissionInfoBodyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 140%;
`;

export const FieldValueStyled = styled.div`
  color: #1b1b1b;
`;

export const AssignedVehicleBodyStyled = styled.div`
  display: flex;
  padding-top: 4px;
`;

export const VehicleIconStyled = styled.img`
  height: 20px;
  padding: 0 8px;
`;

export const VehicleNameStyled = styled.div`
  color: #3f7f7d;
  font-weight: 400;
`;

export const CloseModalButtonStyled = styled.div`
  display: flex;
  align-items: center;
  color: #606060;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 8px;
  }
`;
