import React from "react";

import { FleetPickerDropdown } from "../FleetPickerDropdown/FleetPickerDropdown";

import {
  AsideFiltersWrapperStyled,
  SemanticMapPickerPaddingStyled,
} from "./styles";
import { DelimiterStyled } from "../../../common/Delimeter/styles";
import { SemanticMapPicker } from "Components/common/SemanticMapPicker";

export const AsideFilters = () => (
  <AsideFiltersWrapperStyled>
    <SemanticMapPickerPaddingStyled>
      <SemanticMapPicker />
    </SemanticMapPickerPaddingStyled>
    <DelimiterStyled />
    <FleetPickerDropdown />
  </AsideFiltersWrapperStyled>
);
