import { QueueManagerMissionsData } from "./types";

export const filterMissions = (
  missionsData: QueueManagerMissionsData,
  searchString: string
) => {
  if (!searchString) return missionsData;

  const filteredData: QueueManagerMissionsData = {};

  Object.keys(missionsData).forEach((vehicleId) => {
    const missions = missionsData[vehicleId].filter((mission) =>
      mission.name?.toLowerCase().includes(searchString.toLowerCase())
    );

    filteredData[vehicleId] = missions;
  });

  return filteredData;
};
