import React, { FC, memo } from "react";

import { getMissionStatusData } from "Components/common/MissionStatuses/MissionStatuses";
import { EMPTY_ICON } from "Utils";
import { parseDateTime } from "../../utils";

import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/closeIcon.svg";
import {
  MissionDetailsHeaderWrapperStyled,
  MissionDetailBodyStyled,
  MissionNameStyled,
  AdditionalMissionInfoBodyStyled,
  FieldValueStyled,
  RectangleIconBodyStyled,
  StatusBodyStyled,
  StatusNameStyled,
  AssignedVehicleBodyStyled,
  VehicleIconStyled,
  VehicleNameStyled,
  CloseModalButtonStyled,
} from "./styles";

type MissionDetailsHeaderProps = {
  mission: QueueManagerMission;
  vehicle: Vehicle;
  vehicleType: VehicleType;
  handleCloseAction: () => void;
};

export const MissionDetailsHeader: FC<MissionDetailsHeaderProps> = memo(
  ({ mission, vehicle, vehicleType, handleCloseAction }) => {
    const { icon, statusName } = getMissionStatusData(mission.status);

    const parsedDateTime = mission.deployedAt
      ? parseDateTime(mission.deployedAt)
      : "Invalid Date";

    return (
      <MissionDetailsHeaderWrapperStyled>
        <MissionDetailBodyStyled>
          <StatusBodyStyled>
            {icon}
            <StatusNameStyled>{statusName}</StatusNameStyled>
          </StatusBodyStyled>
          <MissionNameStyled>{mission.name}</MissionNameStyled>
          <AdditionalMissionInfoBodyStyled>
            Deployed at:
            <FieldValueStyled>{parsedDateTime}</FieldValueStyled>
            <RectangleIconBodyStyled>
              <RectangleIcon />
            </RectangleIconBodyStyled>
            Mission Source:
            <FieldValueStyled>
              {mission.missionSource || "No Mission Source"}
            </FieldValueStyled>
          </AdditionalMissionInfoBodyStyled>
          <AssignedVehicleBodyStyled>
            Assigned to:
            <VehicleIconStyled src={vehicleType?.image?.link || EMPTY_ICON} />
            <VehicleNameStyled>{vehicle.name}</VehicleNameStyled>
          </AssignedVehicleBodyStyled>
        </MissionDetailBodyStyled>
        <CloseModalButtonStyled onClick={handleCloseAction}>
          Close <CloseIcon />
        </CloseModalButtonStyled>
      </MissionDetailsHeaderWrapperStyled>
    );
  }
);
