import React, { FC, memo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  fetchPostMissionPlan,
  fetchDeployMissionPlan,
} from "Slices/missionPlans";
import { FMSLogger } from "FMSLogger";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { EMPTY_ICON } from "Utils";
import { DeployedMissionList } from "../../../DeployedMissionList/DeployedMissionList";
import { MissionPlannerData } from "Slices/appState/reducers";

import {
  MissionDetailsWrapperStyled,
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
  MissionPlannerHeaderStyled,
} from "../../../MissionPlanDetails/styles";
import {
  MissionPlannerStyled,
  MissionPlannerBackgroundStyled,
  MissionPlannerLabelStyled,
  MissionPlannerRouteNameStyled,
  MissionPlannerTabBtnStyled,
  MissionPlannerTabsStyled,
  MissionPlannerHeadContentStyled,
  MissionPlannerHeadLeftStyled,
  MissionPlannerHeadInfoStyled,
  ImgStyled,
  DeployedMissionListBodyStyled,
} from "./styles";
import { MissionPlanDetails } from "Components/MissionPlanDetails/MissionPlanDetails";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlannerProps = {
  missionPlannerData: MissionPlannerData;
  setMissionPlannerData: (
    missionPlannerData: MissionPlannerData | null
  ) => void;
};

export const MissionPlanner: FC<MissionPlannerProps> = memo(
  ({ missionPlannerData, setMissionPlannerData }) => {
    const [editedMissionPlan, setEditedMissionPlan] =
      useState<null | MissionAsset>(null);
    const dispatch = useDispatch();
    const addPlanMissionAction = (data: MissionAsset) =>
      dispatch(fetchPostMissionPlan(data));
    const deployPlanMissionAction = (data: MissionAsset) =>
      dispatch(fetchDeployMissionPlan({ ...data }));
    const [activeTab, setActiveTab] = useState("savedMission");

    return (
      <MissionPlannerStyled>
        <MissionPlannerBackgroundStyled
          onClick={() => setMissionPlannerData(null)}
        />
        <MissionPlannerContainerStyled>
          <MissionPlannerContentStyled withHeader>
            <MissionPlannerHeadContentStyled>
              <MissionPlannerHeaderStyled>
                Mission Planner
              </MissionPlannerHeaderStyled>
              <MissionPlannerHeadInfoStyled>
                <ImgStyled
                  src={
                    missionPlannerData?.vehicleType?.image?.link || EMPTY_ICON
                  }
                />
                <MissionPlannerHeadLeftStyled>
                  <MissionPlannerLabelStyled>
                    You are planning a mission for
                  </MissionPlannerLabelStyled>
                  <MissionPlannerRouteNameStyled>
                    {missionPlannerData?.vehicleName}
                  </MissionPlannerRouteNameStyled>
                </MissionPlannerHeadLeftStyled>
              </MissionPlannerHeadInfoStyled>
            </MissionPlannerHeadContentStyled>
            <MissionPlannerTabsStyled>
              <MissionPlannerTabBtnStyled
                onClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "choose tab with mission list"
                  );
                  setActiveTab("savedMission");
                }}
                active={activeTab === "savedMission"}
              >
                {editedMissionPlan ? "Edit Mission" : "Mission List"}
              </MissionPlannerTabBtnStyled>
              <MissionPlannerTabBtnStyled
                onClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "choose tab with new mission"
                  );
                  setActiveTab("newMission");
                }}
                active={activeTab === "newMission"}
              >
                Create New Mission
              </MissionPlannerTabBtnStyled>
            </MissionPlannerTabsStyled>
            <DeployedMissionListBodyStyled>
              <DeployedMissionList
                currentFleetId={""}
                vehicleName={missionPlannerData?.vehicleName}
                editedMissionPlan={editedMissionPlan}
                setEditedMissionPlan={setEditedMissionPlan}
                vehicleId={missionPlannerData?.vehicleId}
                semanticMapId={missionPlannerData?.semanticMap}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                deployPlanMissionAction={deployPlanMissionAction}
                cancelAction={() => setMissionPlannerData(null)}
                previousMode="Cancel"
              />
              {activeTab === "newMission" && (
                <MissionDetailsWrapperStyled>
                  <MissionPlanDetails
                    changeTabAction={() => setActiveTab("savedMission")}
                    cancelAction={() => setMissionPlannerData(null)}
                    addPlanMissionAction={addPlanMissionAction}
                    deployPlanMissionAction={deployPlanMissionAction}
                    vehicleName={missionPlannerData?.vehicleName}
                    vehicleId={missionPlannerData?.vehicleId}
                    semanticMapId={missionPlannerData?.semanticMap}
                    previousMode="Cancel"
                  />
                </MissionDetailsWrapperStyled>
              )}
            </DeployedMissionListBodyStyled>
          </MissionPlannerContentStyled>
        </MissionPlannerContainerStyled>
      </MissionPlannerStyled>
    );
  }
);
