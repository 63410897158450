export const MISSION_PANNER_WS = "mission-planner:mission-queue:websocket";
export const MISSION_OPERATOR_WS = "mission-operator:mission-queue:websocket";
export const VEHICLE_STATE_WS = "realtime-map:vehicle-state:websocket";
export const VEHICLE_LIST_MISSIONS_WS = "vehicle-list:mission-queue:websocket";
export const VEHICLE_LIST_STATE_WS = "vehicle-list:vehicle-state:websocket";
export const MISSION_OPERATOR_ACTIONS = "user-actions:mission-operator";
export const MISSION_PLANNER_ACTIONS = "user-actions:mission-planner";
export const LIVE_MISSIONS_ACTIONS = "user-actions:live-missions";
export const QUEUE_MANAGER_ACTIONS = "user-actions:queue-manager";
export const MISSIONS_ACTIONS = "user-actions:missions-page";
export const MISSION_DEPLOYMENT_ACTIONS = "user-actions:mission-deployment";
export const NAVIGATION_BAR_ACTIONS = "user-actions:navigation-bar";
export const TIMEOUT_LOGS = "timeout-logs";
export const LOCATION_LOGS = "location-logs";
export const WS_SPINNER_LOGS = "websocket-connection-spinner";
export const MISSIONS_WS = "missions:mission-queue:websocket";
