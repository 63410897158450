import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentSearchValue } from "Slices/missionPlanner";
import { FMSLogger } from "FMSLogger";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";

import { ReactComponent as ClearIcon } from "Components/common/assets/clearIcon.svg";
import {
  MissionListSearchStyled,
  SearchInputStyled,
  ClearButtonStyled,
} from "./styles";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

export const MissionListSearch = memo(() => {
  const [searchValue, setSearchValue] = useState<string>("");

  const dispatch = useDispatch();

  useEffect(() => {
    missionPlannerActionsLogger.debug(
      `User typing in search field: ${searchValue}`
    );
    const timer = setTimeout(() => {
      dispatch(setCurrentSearchValue(searchValue));
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue]);

  return (
    <MissionListSearchStyled>
      <SearchInputStyled
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder="Search mission"
      />
      <ClearButtonStyled onClick={() => setSearchValue("")}>
        <ClearIcon />
      </ClearButtonStyled>
    </MissionListSearchStyled>
  );
});
