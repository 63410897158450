import { RefObject, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import { resetUser } from "Slices/currentUser";
import { resetSemanticMaps } from "Slices/semanticMaps";
import { resetFleets } from "Slices/fleets";
import { resetStops } from "Slices/stops";
import { resetRoles } from "Slices/roles";
import { resetVehicleTypes } from "Slices/vehicleTypes";
import { resetCompanies } from "Slices/companies";
import { resetMissionPlannerData } from "Slices/allDeploymentsPage";
import { resetMissionOperatorData } from "Slices/operatorData";
import { resetOperatorMissionPlans } from "Slices/operatorMissionPlans";
import { resetMissionOperatorStation } from "Slices/operatorStation";
import { resetUsers } from "Slices/users";
import { resetVehicles } from "Slices/vehicles";
import { resetVehicleStateData } from "Slices/vehiclesState";
import { resetAppState } from "Slices/appState";
import { resetVehiclesPageData } from "Slices/vehiclesPage";
import { resetMissionPlans } from "Slices/missionPlans";
import { closeWarning } from "Slices/warning";
import { resetLiveQueueDeploymentsPageData } from "Slices/liveQueueDeploymentsPage";
import { resetVehicleSyncState } from "Slices/vehiclesSyncState";
import {
  isArraysEqual,
  isObject,
  isObjectsEqual,
  isPrimitivesEqual,
} from "Utils";

export const useResetEntitiesOnLogOut = () => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetAppState());
      dispatch(resetUser());
      dispatch(resetSemanticMaps());
      dispatch(resetMissionPlans());
      dispatch(resetFleets());
      dispatch(resetStops());
      dispatch(resetRoles());
      dispatch(resetVehicleTypes());
      dispatch(resetCompanies());
      dispatch(resetMissionPlannerData());
      dispatch(resetLiveQueueDeploymentsPageData());
      dispatch(resetMissionOperatorData());
      dispatch(resetOperatorMissionPlans());
      dispatch(resetMissionOperatorStation());
      dispatch(resetUsers());
      dispatch(resetVehicles());
      dispatch(resetVehicleStateData());
      dispatch(resetVehiclesPageData());
      dispatch(resetVehicleSyncState());
      dispatch(closeWarning());
    },
    []
  );
};

export function useOnClickOutside(
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) {
  useEffect(() => {
    const handleClick = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Element)) {
        callback();
      }
    };
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [callback]);
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useIsFieldChanged = (
  newFields: Array<unknown>,
  isShouldStartTrackChanges = true
): boolean => {
  const initialFields = useMemo(() => {
    if (!isShouldStartTrackChanges) return "init";

    return newFields;
  }, [isShouldStartTrackChanges]);

  if (initialFields === "init") return false;

  return initialFields.some((value, index) => {
    const newValue = newFields[index];

    if (Array.isArray(value)) {
      return !isArraysEqual(value, newValue as Array<unknown>);
    } else if (isObject(value) && isObject(newValue)) {
      return !isObjectsEqual(value, newValue);
    } else {
      return !isPrimitivesEqual(value, newValue);
    }
  });
};
