import React, { FC, memo, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { MissionRow } from "../MissionRow/MissionRow";
import { toggleModal } from "Slices/modals";

import { ReactComponent as SelectIcon } from "../../assets/selectIcon.svg";
import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as ProgressIcon } from "../../assets/progressIcon.svg";
import { ReactComponent as PolygonIcon } from "../../assets/polygonIcon.svg";
import {
  DraggableItemStyled,
  DraggableContainerStyled,
  VehicleTitleStyled,
  VehicleGroupContainerStyled,
  VehicleTitleContainerStyled,
  ActiveMissionBodyStyled,
  ActiveMissionStyled,
  SelectIconBodyStyled,
  CounterMissionsContainerStyled,
  CounterValueStyled,
  CounterStyled,
} from "./styles";

type VehicleSectionProps = {
  vehicleId: VehicleId;
  vehicleName: string;
  missions: Mission[];
  activeMission?: Mission;
};

export const MissionsByVehicleSection: FC<VehicleSectionProps> = memo(
  ({ vehicleId, vehicleName, missions, activeMission }) => {
    const dispatch = useDispatch();
    const [isShowMissions, setIsShowMissions] = useState(missions.length === 0);

    const handleExpandMissions = () => {
      if (missions.length === 0) return;
      setIsShowMissions(!isShowMissions);
    };

    const handleOpenMissionDetails = (event: MouseEvent<HTMLDivElement>) => {
      if (activeMission) {
        dispatch(
          toggleModal({
            type: "MISSION_DETAILS_MODAL",
            data: { missionId: activeMission.id },
          })
        );
        event.stopPropagation();
      }
    };

    return (
      <>
        <VehicleGroupContainerStyled
          isShowMissions={isShowMissions}
          onClick={handleExpandMissions}
        >
          <VehicleTitleContainerStyled>
            <SelectIconBodyStyled isShowMissions={isShowMissions}>
              <SelectIcon />
            </SelectIconBodyStyled>
            <VehicleTitleStyled>{vehicleName}</VehicleTitleStyled>
            <RectangleIcon />
            <ActiveMissionBodyStyled
              onClickCapture={(event) => handleOpenMissionDetails(event)}
            >
              <ProgressIcon />
              <ActiveMissionStyled>
                {activeMission?.name ?? "No active mission"}
              </ActiveMissionStyled>
            </ActiveMissionBodyStyled>
          </VehicleTitleContainerStyled>
          <CounterMissionsContainerStyled isShowMissions={isShowMissions}>
            <CounterStyled isShowMissions={isShowMissions}>
              <PolygonIcon />
              <CounterValueStyled isShowMissions={isShowMissions}>
                {missions.length}
              </CounterValueStyled>
            </CounterStyled>
            {missions.length === 1 ? "Mission" : "Missions"}
          </CounterMissionsContainerStyled>
        </VehicleGroupContainerStyled>
        {isShowMissions && (
          <Droppable droppableId={vehicleId} key={vehicleId}>
            {(provided) => (
              <DraggableContainerStyled
                isShowMissions={isShowMissions}
                isNoMissions={missions.length === 0}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {missions.map((mission, index) => (
                  <Draggable
                    // remove isDragDisabled to enable dnd
                    isDragDisabled={true}
                    key={mission.id}
                    draggableId={mission.id}
                    index={index}
                  >
                    {(itemProvided) => (
                      <DraggableItemStyled
                        ref={itemProvided.innerRef}
                        {...itemProvided.draggableProps}
                        {...itemProvided.dragHandleProps}
                      >
                        <MissionRow mission={mission} />
                      </DraggableItemStyled>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DraggableContainerStyled>
            )}
          </Droppable>
        )}
      </>
    );
  }
);
