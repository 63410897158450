import React, { useState, useRef, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/browser";

import { ReactComponent as HelpIcon } from "../../assets/helpIcon.svg";
// import { ReactComponent as GettingStartedIcon } from "../../assets/gettingStarted.svg";
import { ReactComponent as HelpCenterIcon } from "../../assets/helpCenter.svg";
import { ReactComponent as PhoneIcon } from "../../assets/phone.svg";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { useOnClickOutside } from "Utils/hooks";
import { toggleModal, CONTACT_SUPPORT_MODAL } from "Slices/modals";

import {
  HelpDropdownStyled,
  HelpDropdownBtnStyled,
  HelpDropdownContentStyled,
  HelpDropdownLinkStyled,
  HelpDropdownBtnItemStyled,
} from "./styles";
import { FMSLogger } from "FMSLogger";
import { NAVIGATION_BAR_ACTIONS } from "Utils/constants";

const navigationBarActionsLogger = FMSLogger.byPrefix(NAVIGATION_BAR_ACTIONS);

interface DropdownItem {
  id: string;
  text: string;
  link: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const dropdownConfig: Array<DropdownItem> = [
  // {
  //   text: "Getting Started",
  //   link: "https://scribehow.com/page/Getting_Started_with_your_DriveMod_Stockchaser__oC5nSUlSTd2Ux1DCWZ66Kg",
  //   Icon: GettingStartedIcon,
  // },
  {
    id: "help_center",
    text: "Help Center",
    link: "https://scribehow.com/page/Cyngn_Help_Center__lYmRc-4_TimtsRHgbh0mNA",
    Icon: HelpCenterIcon,
  },
];

export const HelpDropdown = memo(() => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setShowDropdown(false), []);
  useOnClickOutside(ref, handleClickOutside);

  const handleOpenContactSupportModal = useCallback(() => {
    navigationBarActionsLogger.debug("invoked contact support modal");
    dispatch(toggleModal({ type: CONTACT_SUPPORT_MODAL }));
    setShowDropdown(false);
  }, []);

  const handleOpenSentryDialog = useCallback(() => {
    navigationBarActionsLogger.debug("invoked sentry dialog");
    Sentry.captureException(new Error("User Report"));
    Sentry.showReportDialog();
    setShowDropdown(false);
  }, []);

  return (
    <HelpDropdownStyled ref={ref}>
      <HelpDropdownBtnStyled
        data-test-id="help"
        onClick={() => setShowDropdown(!showDropdown)}
        active={showDropdown}
      >
        <HelpIcon />
        Help
      </HelpDropdownBtnStyled>
      {showDropdown && (
        <HelpDropdownContentStyled>
          {dropdownConfig.map(({ id, text, link, Icon }) => {
            return (
              <HelpDropdownLinkStyled
                data-test-id={id}
                key={id}
                target="_blank"
                href={link}
                onClick={() => {
                  navigationBarActionsLogger.debug(
                    `click on help link ${link}`
                  );
                }}
              >
                <Icon />
                {text}
              </HelpDropdownLinkStyled>
            );
          })}
          <HelpDropdownBtnItemStyled
            data-test-id="contact_support"
            onClick={handleOpenContactSupportModal}
          >
            <PhoneIcon />
            Contact Support
          </HelpDropdownBtnItemStyled>
          <HelpDropdownBtnItemStyled
            data-test-id="report_problem"
            onClick={handleOpenSentryDialog}
          >
            <Warning />
            Report a Problem
          </HelpDropdownBtnItemStyled>
        </HelpDropdownContentStyled>
      )}
    </HelpDropdownStyled>
  );
});
