import React, { memo, useContext } from "react";
import { FMSLogger } from "FMSLogger";
import { MISSIONS_ACTIONS } from "Utils/constants";

import { ReactComponent as ClearIcon } from "Components/common/assets/clearIcon.svg";
import {
  MissionListSearchStyled,
  SearchInputStyled,
  ClearButtonStyled,
} from "./styles";
import { MissionsPageSearchContext } from "Components/MissionsPage/SearchContextHelper";

const missionsActionsLogger = FMSLogger.byPrefix(MISSIONS_ACTIONS);

export const MissionListSearch = memo(() => {
  const { setSearchValue, searchValue } = useContext(MissionsPageSearchContext);

  const handleChangeSearchValue = (searchValue: string) => {
    missionsActionsLogger.debug(`User typing in search field: ${searchValue}`);
    setSearchValue?.(searchValue);
  };

  return (
    <MissionListSearchStyled>
      <SearchInputStyled
        value={searchValue}
        onChange={(event) => handleChangeSearchValue(event.target.value)}
        placeholder="Search mission"
      />
      <ClearButtonStyled onClick={() => handleChangeSearchValue("")}>
        <ClearIcon />
      </ClearButtonStyled>
    </MissionListSearchStyled>
  );
});
