import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPatchMissionPlan } from "Slices/missionPlans";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import { MissionDetailsWrapperStyled } from "Components/MissionPlanDetails/styles";
import { AssignModal } from "Components/MissionPlanDetails/AssignModal";
import { MissionsModal } from "Components/MissionPlanDetails/MissionsModal";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";

import { MissionPlansList } from "./components/MissionPlansList/MissionPlansList";
import { MissionPlanDetails } from "Components/MissionPlanDetails/MissionPlanDetails";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlansListProps = {
  vehicleName?: string;
  editedMissionPlan: MissionAsset | null;
  setEditedMissionPlan: Dispatch<SetStateAction<null | MissionAsset>>;
  vehicleId?: string;
  semanticMapId: string | null;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>> | null;
  deployPlanMissionAction: (data: MissionAsset) => void;
  cancelAction: () => void;
  previousMode: string;
  currentFleetId: string;
};

export const DeployedMissionList: FC<MissionPlansListProps> = ({
  vehicleName,
  editedMissionPlan,
  setEditedMissionPlan,
  vehicleId,
  semanticMapId,
  activeTab,
  setActiveTab,
  deployPlanMissionAction,
  cancelAction,
  previousMode,
  currentFleetId,
}) => {
  const dispatch = useDispatch();
  const [savedMissionsDisabled, setSavedMissionsDisabled] = useState(false);
  const [isShowAssignPicker, setShowAssignPicker] = useState(false);
  const [currentMissionData, setCurrentMissionData] =
    useState<null | MissionAsset>(null);
  const [confirmOverwriteMissionData, setConfirmOverwriteMissionData] =
    useState<null | {
      text: string;
      confirmAction: () => void;
      cancelAction: () => void;
    }>(null);

  const vehiclesUpdateData = useSelector(getVehiclesUpdateData);

  const patchPlanMissionAction = (data: {
    hideToast: boolean;
    data: {
      timeStamp: Date;
      previous: string;
      data: null;
      semanticMap: string;
      name: string;
      loops: number;
      id: string;
      stops: Array<MissionPlanStop>;
      updatedAt: string;
    };
    id: string;
  }) => dispatch(fetchPatchMissionPlan(data));

  const disableSavedMissions = () => {
    if (!savedMissionsDisabled) {
      setSavedMissionsDisabled(true);
      setTimeout(() => {
        setSavedMissionsDisabled(false);
      }, 6000);
    }
  };

  const deployAction = (missionData: MissionAsset) => () => {
    if (savedMissionsDisabled) {
      return;
    }
    setCurrentMissionData(missionData);
    setShowAssignPicker(true);
  };

  const deploy = (
    missionData: MissionAsset | null,
    assigneeData: {
      vehicle?: string;
      fleet?: string;
      loops: number;
    }
  ) => {
    if (!missionData) return;
    deployPlanMissionAction({
      ...missionData,
      ...assigneeData,
      previous: previousMode,
    });
    patchPlanMissionAction({
      id: missionData.id,
      data: { ...missionData, previous: previousMode, timeStamp: new Date() },
      hideToast: true,
    });
    disableSavedMissions();
    setConfirmOverwriteMissionData(null);
  };

  const deployWithOverwrite = (
    missionData: MissionAsset | null,
    assigneeData: {
      vehicle: string;
      loops: number;
    }
  ) => {
    if (
      previousMode !== "Continue" &&
      vehiclesUpdateData &&
      vehiclesUpdateData[assigneeData.vehicle] &&
      vehiclesUpdateData[assigneeData.vehicle].mission_status === "IN_PROGRESS"
    ) {
      setConfirmOverwriteMissionData({
        text: "This vehicle has a mission in progress. Would you like to cancel the current mission and redeploy this one?",
        confirmAction: () => {
          missionDeploymentActionsLogger.debug("deploy mission with overwrite");
          deploy(missionData, assigneeData);
        },
        cancelAction: () => {
          missionDeploymentActionsLogger.debug(
            "cancel deploy mission with overwrite"
          );
          setConfirmOverwriteMissionData(null);
        },
      });
      return;
    }
    missionDeploymentActionsLogger.debug("deploy mission");
    deploy(missionData, assigneeData);
  };

  return (
    <>
      {!confirmOverwriteMissionData &&
        isShowAssignPicker &&
        currentMissionData && (
          <AssignModal
            currentFleetId={currentFleetId}
            vehicleId={vehicleId}
            vehicleName={vehicleName}
            currentMissionDataStops={currentMissionData.stops}
            handleVehiclePick={(id, selectedLoopsValue) => {
              deployWithOverwrite(currentMissionData, {
                vehicle: id,
                loops: Number(selectedLoopsValue),
              });
              setShowAssignPicker(false);
            }}
            handleFleetPick={(id, selectedLoopsValue) => {
              missionDeploymentActionsLogger.debug("deploy mission to fleet");
              deploy(currentMissionData, {
                fleet: id,
                loops: Number(selectedLoopsValue),
              });
              setShowAssignPicker(false);
            }}
            cancelAction={() => setShowAssignPicker(false)}
          />
        )}
      {confirmOverwriteMissionData && (
        <MissionsModal {...confirmOverwriteMissionData} />
      )}
      {activeTab === "savedMission" && !editedMissionPlan && (
        <MissionPlansList
          isShowAssignPicker={isShowAssignPicker}
          setActiveTab={setActiveTab}
          cancelAction={cancelAction}
          setEditedMissionPlan={setEditedMissionPlan}
          deployAction={deployAction}
          savedMissionsDisabled={savedMissionsDisabled}
        />
      )}
      {activeTab === "savedMission" && editedMissionPlan && (
        <MissionDetailsWrapperStyled>
          <MissionPlanDetails
            editedMissionPlan={editedMissionPlan}
            cancelAction={() => setEditedMissionPlan(null)}
            patchPlanMissionAction={patchPlanMissionAction}
            deployPlanMissionAction={deployPlanMissionAction}
            vehicleId={vehicleId}
            semanticMapId={semanticMapId}
            previousMode={previousMode}
          />
        </MissionDetailsWrapperStyled>
      )}
    </>
  );
};
