import {
  fetchLiveQueueDeployment,
  cancelLiveQueueDeployment,
  changeLiveQueueDeploymentOrder,
} from "./actions";
import {
  getLiveQueueDeploymentsPageFleetId,
  getLiveQueueDeployments,
  getActiveLiveQueueDeploymentByVehicleId,
  getQueueDeployments,
  getLiveDeployments,
  getIsLiveQueueDeploymentsUpdatesPaused,
  getLiveQueueDeploymentsUpdatesStorage,
  getLiveQueueDeploymentsById,
  getIsLiveQueueDeploymentsUpdating,
} from "./selectors";
import LiveQueueDeploymentsSlice from "./reducers";

// actions
export const {
  setLiveQueueDeployments,
  setLiveQueueDeploymentsPageFleetId,
  updateLiveQueueDeployments,
  resetLiveQueueDeployments,
  reorderLiveQueueDeployments,
  pauseLiveQueueDeploymentsUpdates,
  continueLiveQueueDeploymentsUpdates,
  resetLiveQueueDeploymentsUpdatesStorage,
  resetLiveQueueDeploymentsPageData,
  setIsLiveQueueDeploymentsUpdating,
} = LiveQueueDeploymentsSlice.actions;

// async actions
export {
  fetchLiveQueueDeployment,
  cancelLiveQueueDeployment,
  changeLiveQueueDeploymentOrder,
};

// selectors
export {
  getLiveQueueDeploymentsPageFleetId,
  getLiveQueueDeployments,
  getActiveLiveQueueDeploymentByVehicleId,
  getQueueDeployments,
  getLiveDeployments,
  getIsLiveQueueDeploymentsUpdatesPaused,
  getLiveQueueDeploymentsUpdatesStorage,
  getLiveQueueDeploymentsById,
  getIsLiveQueueDeploymentsUpdating,
};

// reducer
export default LiveQueueDeploymentsSlice.reducer;

// constants
export const UNASSIGNED_DEPLOYMENT_KEY = "unassigned";
