import { RootState } from "../..";
import {
  ADD_MISSION_MODAL,
  CHANGE_COMPANY_MODAL,
  CONFIRM_ACTIVE_ROLE_USER_MODAL,
  CONFIRM_ACTIVE_STATUS_USER_MODAL,
  CONFIRM_CHANGE_VECHICLE_MODAL,
  CONTACT_SUPPORT_MODAL,
  DEPLOYED_MISSION_LIST_MODAL,
  FLEET_MODAL,
  GAMEPAD_SETTINGS_MODAL,
  INVITATION_SENT_MODAL,
  INVITE_USER_MODAL,
  USER_DETAILS_MODAL,
  USER_MODAL,
  VEHICLE_MODAL,
  VEHICLE_TYPE_MODAL,
  VERSION_MODAL,
  MISSION_DETAILS_MODAL,
} from ".";

export const getIsUserDetailsModal = (state: RootState) =>
  state.modals.type === USER_DETAILS_MODAL;
export const getIsConfirmActiveStatusUserModal = (state: RootState) =>
  state.modals.type === CONFIRM_ACTIVE_STATUS_USER_MODAL;
export const getIsConfirmActiveRoleUserModal = (state: RootState) =>
  state.modals.type === CONFIRM_ACTIVE_ROLE_USER_MODAL;
export const getIsConfirmChangeVehicleModal = (state: RootState) =>
  state.modals.type === CONFIRM_CHANGE_VECHICLE_MODAL;
export const getIsInvitationSentModal = (state: RootState) =>
  state.modals.type === INVITATION_SENT_MODAL;
export const getIsInviteUserModal = (state: RootState) =>
  state.modals.type === INVITE_USER_MODAL;
export const getIsShowVehicleModal = (state: RootState) =>
  state.modals.type === VEHICLE_MODAL;
export const getIsShowVehicleTypeModal = (state: RootState) =>
  state.modals.type === VEHICLE_TYPE_MODAL;
export const getIsShowFleetModal = (state: RootState) =>
  state.modals.type === FLEET_MODAL;
export const getIsShowGamepadSettingModal = (state: RootState) =>
  state.modals.type === GAMEPAD_SETTINGS_MODAL;
export const getIsShowUserModal = (state: RootState) =>
  state.modals.type === USER_MODAL;
export const getIsShowVersionModal = (state: RootState) =>
  state.modals.type === VERSION_MODAL;
export const getIsShowChangeCompanyModal = (state: RootState) =>
  state.modals.type === CHANGE_COMPANY_MODAL;
export const getIsShowAddMissionModal = (state: RootState) =>
  state.modals.type === ADD_MISSION_MODAL;
export const getIsShowContactSupportModal = (state: RootState) =>
  state.modals.type === CONTACT_SUPPORT_MODAL;
export const getIsShowDeployedMissionListModal = (state: RootState) =>
  state.modals.type === DEPLOYED_MISSION_LIST_MODAL;
export const getIsShowMissionDetailsModal = (state: RootState) =>
  state.modals.type === MISSION_DETAILS_MODAL;
export const getIsModalOpen = (state: RootState) => Boolean(state.modals.type);
export const getModalData = (state: RootState) => state.modals.data;
