import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal, getModalData } from "Slices/modals";
import { getMissionById } from "Slices/missionsPage";
import { getVehicleById } from "Slices/vehicles";
import { getVehicleTypeById } from "Slices/vehicleTypes";
import { MissionDataLoadingError } from "Components/common/MissionDataLoadingError";
import { MissionDetailsHeader } from "./components/MissionDetailsHeader/MissionDetailsHeader";
import { MissionDetailsBody } from "./components/MissionDetailsBody/MissionDetailsBody";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "Components/common/ModalComponent/styles";
import { MissionDetailsModalWrapperStyled } from "./styles";

export const MissionDetailsModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const currentMission = useSelector(
    getMissionById(modalData?.missionId as string | undefined)
  ) as QueueManagerMission;
  const currentVehicle = useSelector(
    getVehicleById(currentMission?.vehicle as string)
  );
  const currentVehicleType = useSelector(
    getVehicleTypeById(currentVehicle?.vehicleTypeId)
  );

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const isMissionDataFailed =
    !currentMission || !currentVehicle || !currentVehicleType;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <MissionDetailsModalWrapperStyled>
        {isMissionDataFailed ? (
          <MissionDataLoadingError errorMessage="Error loading mission data" />
        ) : (
          <>
            <MissionDetailsHeader
              mission={currentMission}
              vehicle={currentVehicle}
              vehicleType={currentVehicleType}
              handleCloseAction={handleCloseAction}
            />
            <MissionDetailsBody mission={currentMission} />
          </>
        )}
      </MissionDetailsModalWrapperStyled>
    </ModalStyled>
  );
});
