import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Utils";
import { UNASSIGNED_DEPLOYMENT_KEY } from "Slices/liveQueueDeploymentsPage/index";

export const getLiveQueueDeploymentsPageFleetId = (state: RootState) =>
  state.liveQueueDeploymentsPage.liveQueueDeploymentsPageFleetId;
export const getLiveQueueDeployments = (state: RootState) =>
  state.liveQueueDeploymentsPage.liveQueueDeployments;
export const getActiveLiveQueueDeploymentByVehicleId = createSelector(
  getLiveQueueDeployments,
  (deployments) => {
    if (!deployments) return EMPTY_OBJECT;
    return deployments.reduce(
      (acc: Record<VehicleId, Deployment>, deployment) => {
        if (
          deployment.vehicleId &&
          ["IN_PROGRESS", "PAUSED"].includes(
            deployment?.vehicleDeployment?.status ?? ""
          )
        ) {
          if (!acc[deployment.vehicleId]) {
            acc[deployment.vehicleId] = deployment;
          }
        }
        return acc;
      },
      {}
    );
  }
);

const addDeploymentsByVehicle = (
  store: Record<VehicleId, Deployment[]>,
  vehicleId: string,
  deployment: Deployment
) => {
  if (store[vehicleId]) {
    store[vehicleId] = store[vehicleId].concat(deployment);
  } else {
    store[vehicleId] = [deployment];
  }
};

export const getQueueDeployments = createSelector(
  getLiveQueueDeployments,
  (deployments) => {
    if (!deployments) return EMPTY_OBJECT;
    return deployments.reduce(
      (acc: Record<VehicleId, Deployment[]>, deployment) => {
        if (
          ["NEW", "SENT", "RECEIVED"].includes(
            deployment?.vehicleDeployment?.status ?? ""
          ) ||
          !deployment.vehicleDeployment
        ) {
          addDeploymentsByVehicle(
            acc,
            deployment.vehicleId ?? UNASSIGNED_DEPLOYMENT_KEY,
            deployment
          );
        }
        return acc;
      },
      {}
    );
  }
);

export const getLiveDeployments = createSelector(
  getLiveQueueDeployments,
  (deployments) => {
    if (!deployments) return EMPTY_ARRAY;
    return deployments.filter((deployment) =>
      ["IN_PROGRESS", "PAUSED"].includes(
        deployment?.vehicleDeployment?.status ?? ""
      )
    );
  }
);

export const getLiveQueueDeploymentsById = (deploymentId: string | undefined) =>
  createSelector(getLiveQueueDeployments, (deployments) => {
    if (!deployments || !deploymentId) return;
    return deployments.find((deployment) => deployment.id === deploymentId);
  });

export const getIsLiveQueueDeploymentsUpdatesPaused = (state: RootState) =>
  state.liveQueueDeploymentsPage.isLiveQueueDeploymentsUpdatesPaused;
export const getLiveQueueDeploymentsUpdatesStorage = (state: RootState) =>
  state.liveQueueDeploymentsPage.liveQueueDeploymentsUpdatesStorage;
export const getIsLiveQueueDeploymentsUpdating = (state: RootState) =>
  state.liveQueueDeploymentsPage.isLiveQueueDeploymentsUpdating;
