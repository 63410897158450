import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { HeaderControlsStyled, HeaderStyled, TitleStyled } from "./styles";
import { AddMissionsDropdown } from "../AddMissionsDropdown/AddMissionsDropdown";
import { MissionListSearch } from "Components/MissionsPage/components/MissionListSearch/MissionListSearch";
import {
  LIVE_MISSIONS_PATH,
  QUEUE_MISSIONS_PATH,
} from "../../../../routers/AuthorizedRouter";

export const Header = memo(() => {
  const { pathname } = useLocation();

  const title = useMemo(() => {
    if (pathname === LIVE_MISSIONS_PATH) return "Live Missions";
    else if (pathname === QUEUE_MISSIONS_PATH) return "Queue Missions";
    else return "Missions";
  }, [pathname]);

  return (
    <HeaderStyled>
      <TitleStyled>{title}</TitleStyled>
      <HeaderControlsStyled>
        <MissionListSearch />
        <AddMissionsDropdown />
      </HeaderControlsStyled>
    </HeaderStyled>
  );
});
