import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Utils";
import { UNASSIGNED_MISSIONS_KEY } from "Slices/missionsPage/index";

export const getMissionsPageFleetId = (state: RootState) =>
  state.missionsPage.missionsPageFleetId;
export const getMissions = (state: RootState) => state.missionsPage.missions;
export const getActiveMissionByVehicleId = createSelector(
  getMissions,
  (missions) => {
    if (!missions) return EMPTY_OBJECT;
    return missions.reduce((acc: Record<VehicleId, Mission>, mission) => {
      if (["IN_PROGRESS", "PAUSED"].includes(mission.status)) {
        if (!acc[mission.vehicle]) {
          acc[mission.vehicle] = mission;
        }
      }
      return acc;
    }, {});
  }
);

const addMissionByVehicle = (
  store: Record<VehicleId, Mission[]>,
  vehicleId: string,
  mission: Mission
) => {
  if (store[vehicleId]) {
    store[vehicleId] = store[vehicleId].concat(mission);
  } else {
    store[vehicleId] = [mission];
  }
};

export const getQueueManagerMissionsData = createSelector(
  getMissions,
  (missions) => {
    if (!missions) return EMPTY_OBJECT;
    return missions.reduce((acc: Record<VehicleId, Mission[]>, mission) => {
      if (["NEW", "SENT"].includes(mission.status)) {
        addMissionByVehicle(
          acc,
          mission.vehicle ?? UNASSIGNED_MISSIONS_KEY,
          mission
        );
      }
      return acc;
    }, {});
  }
);

export const getLiveMissionsData = createSelector(getMissions, (missions) => {
  if (!missions) return EMPTY_ARRAY;
  return missions.filter((mission) =>
    ["IN_PROGRESS", "PAUSED"].includes(mission.status)
  );
});

export const getMissionById = (missionId: string | undefined) =>
  createSelector(getMissions, (missions) => {
    if (!missions || !missionId) return;
    return missions.find((mission) => mission.id === missionId);
  });

export const getIsMissionsUpdatesPaused = (state: RootState) =>
  state.missionsPage.isMissionsUpdatesPaused;
export const getMissionsUpdatesStorage = (state: RootState) =>
  state.missionsPage.missionsUpdatesStorage;
