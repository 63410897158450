import React, { FC, memo, useMemo } from "react";

import { AbstractWarning } from "Components/Warning/AbstractWarning";

import {
  ActionButtonContainer,
  ActionButtonStyled,
  CancelButtonStyled,
} from "Components/Warning/styles";
import { UniqTextStyled } from "./styles";

type OverrideMissionWarningProps = {
  missionId: string | null;
  vehicleName: string | null;
  handleCloseAction: () => void;
  handleSuccessAction: (missionId: string) => void;
};

const OVERRIDE_MISSION_WARNING_MESSAGE =
  " is actively executing a mission. Overriding the current mission will mark it as complete and any new mission you deploy will immediately begin execution.";

export const OverrideMissionWarning: FC<OverrideMissionWarningProps> = memo(
  ({ missionId, vehicleName, handleCloseAction, handleSuccessAction }) => {
    const message = useMemo(
      () => (
        <>
          <UniqTextStyled>{vehicleName}</UniqTextStyled>
          {OVERRIDE_MISSION_WARNING_MESSAGE}
        </>
      ),
      [vehicleName]
    );

    const actionHandler = useMemo(() => {
      return (
        <ActionButtonContainer>
          <CancelButtonStyled
            onClick={() => {
              handleCloseAction();
            }}
          >
            cancel
          </CancelButtonStyled>
          <ActionButtonStyled
            onClick={() => {
              missionId && handleSuccessAction(missionId);
            }}
          >
            Override Current Mission
          </ActionButtonStyled>
        </ActionButtonContainer>
      );
    }, [missionId]);

    return <AbstractWarning message={message} actionHandler={actionHandler} />;
  }
);
