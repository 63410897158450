import React, { memo } from "react";
import { QueueManagerTableHeader } from "./components/QueueManagerTableHeader/QueueManagerTableHeader";
import { QueueManagerTableBody } from "./components/QueueManagerTableBody/QueueManagerTableBody";

import { QueueManagerWrapperStyled } from "./styles";

export const QUEUE_MANAGER_WRAPPER = "QueueManagerWrapper";

export const QueueManagerTable = memo(() => (
  <QueueManagerWrapperStyled id={QUEUE_MANAGER_WRAPPER}>
    <QueueManagerTableHeader />
    <QueueManagerTableBody />
  </QueueManagerWrapperStyled>
));
