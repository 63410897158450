import React from "react";

import { getMissionStatusData } from "Components/common/MissionStatuses/MissionStatuses";
import { TableFieldBodyStyled } from "../../../NewList/styles";
import { StatusNameStyled } from "./styles";

export const getDeploymentStatusField = (
  missionStatusCode: MissionStatusCode
) => {
  const { icon, statusName } = getMissionStatusData(missionStatusCode);

  return (
    <TableFieldBodyStyled>
      {icon}
      <StatusNameStyled>{statusName}</StatusNameStyled>
    </TableFieldBodyStyled>
  );
};
