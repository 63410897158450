import React, { memo, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import {
  MissionDetailsWrapperStyled,
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
  MissionPlannerHeaderStyled,
} from "../../MissionPlanDetails/styles";
import { closeAnyModal, getModalData } from "Slices/modals";
import {
  fetchDeployMissionPlan,
  fetchPostMissionPlan,
} from "Slices/missionPlans";
import {
  getActiveTab,
  fetchGetMissionQueue,
  getMissionRowsLimit,
  getMissionPlannerPeriod,
  getSearchValue,
  getSelectedVehicleIds,
} from "Slices/missionPlanner";
import { getCurrentSemanticMapId } from "Slices/appState";
import { MissionPlanDetails } from "Components/MissionPlanDetails/MissionPlanDetails";

export const AddMissionModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const period = useSelector(getMissionPlannerPeriod);
  const limit = useSelector(getMissionRowsLimit);
  const searchValue = useSelector(getSearchValue);
  const selectedVehicleIds = useSelector(getSelectedVehicleIds);
  const activeTab = useSelector(getActiveTab);
  const semanticMapId = useSelector(getCurrentSemanticMapId);

  const currentFleetId = modalData?.fleetId as string;

  // Check currentFleetId existence before component rendered
  useLayoutEffect(() => {
    if (currentFleetId) return;

    toast.error("Please specify particular fleet before create new mission!");
    handleCloseModal();
  }, [currentFleetId]);

  const handleAddPlanMissionAction = useCallback((data: MissionAsset) => {
    return dispatch(fetchPostMissionPlan(data));
  }, []);

  const handleDeployPlanMissionAction = useCallback(
    async (data: { id: string }) => {
      if (!currentFleetId || !period) return;

      await dispatch(
        fetchDeployMissionPlan({
          ...data,
          currentFleetId,
        })
      );
      dispatch(
        fetchGetMissionQueue({
          currentFleetId,
          activeTab,
          period,
          limit,
          searchValue,
          selectedVehicleIds,
          countersOnly: true,
        })
      );
    },
    [currentFleetId, activeTab, selectedVehicleIds, period, searchValue, limit]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <MissionPlannerContainerStyled>
        <MissionPlannerContentStyled>
          <MissionDetailsWrapperStyled>
            <MissionPlannerHeaderStyled>
              Create a new mission
            </MissionPlannerHeaderStyled>
            <MissionPlanDetails
              cancelAction={handleCloseModal}
              addPlanMissionAction={handleAddPlanMissionAction}
              deployPlanMissionAction={handleDeployPlanMissionAction}
              semanticMapId={semanticMapId}
              changeTabAction={handleCloseModal}
              previousMode="Continue"
            />
          </MissionDetailsWrapperStyled>
        </MissionPlannerContentStyled>
      </MissionPlannerContainerStyled>
    </ModalStyled>
  );
});
