import React, { FC, useState } from "react";

import { ButtonComponent } from "../../../common/ButtonComponent/Button.component";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";
import { EMPTY_ICON } from "Utils";

import LocationOpenedIcon from "../../assets/location-opened.svg";
import LocationOpenedIconBlack from "../../assets/location-black-arrow.svg";
import {
  VehicleItemStyled,
  FriendlyNameStyled,
  DetailsStyled,
  VehicleItemLabelStyled,
  VehicleItemTextStyled,
  VehicleDriveModeStyled,
  VehicleItemLineStyled,
  ImgStyled,
  DetailsBlockStyled,
  TopContentStyled,
  TopLeftContentStyled,
  DetailsIconStyled,
} from "./styles";
import { MissionPlannerData } from "Slices/appState/reducers";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type DriveModText = {
  [key: string]: string;
};

const DRIVER_MODE_TEXT: DriveModText = {
  AUTO: "AUTONOMOUS",
  AUTONOMOUS: "AUTONOMOUS",
  MANUAL: "MANUAL DRIVER",
  REMOTE_CONTROL: "REMOTE CONTROL",
  STOPPING: "STOPPING3",
  IDLE: "IDLE",
};

type VehicleItemProps = {
  vehicleData: Vehicle;
  setMissionPlannerData: (missionPlannerData: MissionPlannerData) => void;
  vehicleType: VehicleType | undefined;
  currentSemanticMap: SemanticMap | null;
  vehicleUpdatedData: VehiclesStateDataItem | null;
};

export const VehicleItem: FC<VehicleItemProps> = ({
  vehicleData,
  setMissionPlannerData,
  vehicleType,
  currentSemanticMap,
  vehicleUpdatedData,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  if (!vehicleType) {
    return <></>;
  }

  const { driveMode, webstate, vbStatus, state, id, name } = vehicleData;

  const {
    t_drive_mode: tDriveMode,
    t_vehicle_status: tVehicleStatus,
    t_virtual_bumper_status: tVirtualBumperStatus,
    t_web_status: tWebStatus,
    last_contact: lastContact,
  }:
    | VehicleState
    | {
        [key: string]: string | null;
      } = vehicleUpdatedData && vehicleUpdatedData[id]
    ? vehicleUpdatedData[id]
    : {};

  const currentDriveMod = DRIVER_MODE_TEXT[tDriveMode || driveMode] || "";
  const currentVehicleStatus = tVehicleStatus || webstate || "UNAVAILABLE";
  const currentVirtualBumperStatus =
    tVirtualBumperStatus || vbStatus || "UNAVAILABLE";
  const currentWebStatus = tWebStatus || state || "UNAVAILABLE";

  const semanticMap = currentSemanticMap ? currentSemanticMap.id : null;

  const handlePlanMission = () => {
    missionDeploymentActionsLogger.debug(
      `handle plan mission for vehicle ${id}`
    );
    setMissionPlannerData({
      vehicleId: id,
      vehicleName: name,
      vehicleType,
      semanticMap,
      lastContact: Number(lastContact),
    });
  };

  const openShowDetails = () => {
    missionDeploymentActionsLogger.debug(`toggle vehicle details for ${id}`);
    setShowDetails(!showDetails);
  };

  return (
    <VehicleItemStyled showDetails={showDetails}>
      <TopContentStyled>
        <ImgStyled src={vehicleType.image?.link || EMPTY_ICON} />
        <TopLeftContentStyled>
          <VehicleItemLineStyled>
            <FriendlyNameStyled>{name}</FriendlyNameStyled>
            {currentDriveMod && (
              <VehicleDriveModeStyled text={currentDriveMod.toUpperCase()}>
                {currentDriveMod}
              </VehicleDriveModeStyled>
            )}
          </VehicleItemLineStyled>
          <DetailsStyled
            data-test-id={`vehicle_${name}_details`}
            showDetails={showDetails}
            onClick={openShowDetails}
          >
            Details
            <DetailsIconStyled
              showDetails={showDetails}
              src={showDetails ? LocationOpenedIconBlack : LocationOpenedIcon}
              alt="Location"
            />
          </DetailsStyled>
        </TopLeftContentStyled>
      </TopContentStyled>
      {showDetails && (
        <DetailsBlockStyled>
          <VehicleItemLabelStyled>Vehicle Type</VehicleItemLabelStyled>
          <VehicleItemTextStyled>
            {vehicleType.name.toLowerCase()}
          </VehicleItemTextStyled>
          <VehicleItemLabelStyled>Vehicle Status</VehicleItemLabelStyled>
          <VehicleItemTextStyled>{currentVehicleStatus}</VehicleItemTextStyled>
          <VehicleItemLabelStyled>Virtual Bumper</VehicleItemLabelStyled>
          <VehicleDriveModeStyled
            marginBottom={true}
            text={currentVirtualBumperStatus.toUpperCase()}
          >
            {currentVirtualBumperStatus}
          </VehicleDriveModeStyled>
          <VehicleItemLabelStyled>
            DriveMod Software Status
          </VehicleItemLabelStyled>
          <VehicleDriveModeStyled
            marginBottom={true}
            text={currentWebStatus.toUpperCase()}
          >
            {currentWebStatus === "CALL_FOR_TELEOP"
              ? "Vehicle Stuck"
              : currentWebStatus}
          </VehicleDriveModeStyled>
          <FriendlyNameStyled>
            <strong>Controls</strong>
          </FriendlyNameStyled>
          <ButtonComponent
            clickAction={handlePlanMission}
            posCenter={true}
            type="primary"
            marginBottom={12}
            fontSize={12}
          >
            Plan a Mission
          </ButtonComponent>
        </DetailsBlockStyled>
      )}
    </VehicleItemStyled>
  );
};
