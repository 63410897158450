import React, { memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import {
  getActiveMissionByVehicleId,
  getQueueManagerMissionsData,
} from "Slices/missionsPage/selectors";
import { getVehiclesDict } from "Slices/vehicles/selectors";
import { MissionsPageSearchContext } from "Components/MissionsPage/SearchContextHelper";

import { MissionsByVehicleSection } from "../MissionsByVehicleSection/VehicleSection";
import { filterMissions } from "Components/QueueManagerTable/utils";
import { QueueManagerMissionsData } from "Components/QueueManagerTable/types";

import { QueueManagerTableBodyStyled } from "./styles";
import { UNASSIGNED_MISSIONS_KEY } from "Slices/missionsPage";

export const QueueManagerTableBody = memo(() => {
  const queueManagerMissionsData = useSelector(getQueueManagerMissionsData);
  const vehiclesDict = useSelector(getVehiclesDict);
  const [data, setData] = useState<QueueManagerMissionsData>(
    queueManagerMissionsData
  );
  const activeMissionByVehicleId = useSelector(getActiveMissionByVehicleId);

  const { searchValue } = useContext(MissionsPageSearchContext);

  useEffect(() => {
    const filteredData = filterMissions(queueManagerMissionsData, searchValue);
    setData(filteredData);
  }, [queueManagerMissionsData, searchValue]);

  const onDragEnd = (result: DropResult): void => {
    const currentData = { ...data };

    if (!result.destination) return;

    const { source, destination } = result;

    const sourceItems = [...data[source.droppableId]];
    const destItems = [...data[destination.droppableId]];

    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
    } else {
      destItems.splice(destination.index, 0, removed);

      currentData[destination.droppableId] = destItems;
    }

    currentData[source.droppableId] = sourceItems;
    setData(currentData);
  };

  return (
    <QueueManagerTableBodyStyled>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        {Object.entries(data).map(([vehicleId, missions]) => {
          if (vehicleId === UNASSIGNED_MISSIONS_KEY) {
            return (
              <MissionsByVehicleSection
                key={vehicleId}
                vehicleId={vehicleId}
                vehicleName={"Fleet Unassigned"}
                missions={missions}
              />
            );
          }
          const activeMission = activeMissionByVehicleId[vehicleId];
          return (
            <MissionsByVehicleSection
              key={vehicleId}
              vehicleId={vehicleId}
              vehicleName={vehiclesDict[vehicleId]?.name ?? "..."}
              missions={missions}
              activeMission={activeMission}
            />
          );
        })}
      </DragDropContext>
    </QueueManagerTableBodyStyled>
  );
});
