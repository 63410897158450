import { toast } from "react-toastify";

export const getIsMissionActionsValid = (
  missionActions: Array<MissionAction | MissionActionPatch>
) => {
  // check for emptiness
  if (missionActions.length === 0) {
    toast.error("Error: Actions should not be empty.");
    return false;
  }

  // check for consecutive stops
  const { isMissionActionsHaveConsecutiveStops, errorLines } =
    getIsMissionActionsHaveConsecutiveStops(missionActions);
  if (isMissionActionsHaveConsecutiveStops) {
    toast.error(
      `This mission is invalid and may not reflect a desired behavior. Mission actions have consecutive stops in line${
        errorLines.length ? "s" : ""
      }: ${errorLines.toString().replace(/,/g, ", ")}`
    );
    return false;
  }

  // check for double unhitches
  const {
    isMissionActionsHaveDoubleUnhitches,
    errorLines: errorLinesDoubleUnhitches,
  } = getIsMissionActionsHaveDoubleUnhitches(missionActions);
  if (isMissionActionsHaveDoubleUnhitches) {
    toast.error(
      `This mission is invalid and may not reflect a desired behavior. Mission actions have double unhitch at same stop in line${
        errorLinesDoubleUnhitches.length ? "s" : ""
      }: ${errorLinesDoubleUnhitches.toString().replace(/,/g, ", ")}`
    );
    return false;
  }

  // check last action is go to point
  const isLastActionGoToPoint = getIsLastActionNotGoToPoint(missionActions);
  if (isLastActionGoToPoint) {
    toast.error(
      "This mission is invalid and may not reflect a desired behavior. Last action should be Go to point"
    );
    return false;
  }

  // otherwise return true
  return true;
};

const getIsMissionActionsHaveConsecutiveStops = (
  missionActions: Array<MissionAction | MissionActionPatch>
): { isMissionActionsHaveConsecutiveStops: boolean; errorLines: number[] } => {
  return missionActions.reduce(
    (acc, curr, index) => {
      // Check if the previous action and the current action are both of type GO_TO_POINT (1)
      if (
        index > 0 &&
        missionActions[index - 1].type === 1 &&
        curr.type === 1
      ) {
        acc.isMissionActionsHaveConsecutiveStops = true;
        acc.errorLines.push(index + 1);
      }
      return acc;
    },
    {
      isMissionActionsHaveConsecutiveStops: false as boolean,
      errorLines: [] as number[],
    }
  );
};

const getIsMissionActionsHaveDoubleUnhitches = (
  missionActions: Array<MissionAction | MissionActionPatch>
): { isMissionActionsHaveDoubleUnhitches: boolean; errorLines: number[] } => {
  let unhitchCount = 0;

  return missionActions.reduce(
    (acc, curr, index) => {
      // GO_TO_POINT
      if (curr.type === 1) {
        // Reset the unhitchCount when a new GoTo event is encountered
        unhitchCount = 0;
      }

      // UNHITCH
      if (curr.type === 4) {
        unhitchCount++;
        if (unhitchCount > 1) {
          acc.isMissionActionsHaveDoubleUnhitches = true;
          acc.errorLines.push(index + 1); // Add the line number of the duplicate "Unhitch"
        }
      }

      return acc;
    },
    {
      isMissionActionsHaveDoubleUnhitches: false as boolean,
      errorLines: [] as number[],
    }
  );
};

const getIsLastActionNotGoToPoint = (
  missionActions: Array<MissionAction | MissionActionPatch>
): boolean => {
  let unhitchCount = 0;

  missionActions.forEach((missionAction) => {
    // GO_TO_POINT
    if (missionAction.type === 1) {
      // Reset the unhitchCount when a new GoTo event is encountered
      unhitchCount = 0;
    }

    // UNHITCH
    if (missionAction.type === 4) {
      unhitchCount++;
    }
  });
  return unhitchCount !== 0;
};
