import { z } from "zod";

const MissionActionTypeValidator = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
]);

const MissionActionValidator = z.object({
  id: z.string(),
  name: z.string(),
  duration: z.number(),
  type: MissionActionTypeValidator,
  order: z.number(),
  stopPk: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});

const MissionActionDeployedValidator = MissionActionValidator.extend({
  status: z.number().optional(),
});

const MissionCoreValidator = <T extends z.ZodType<any, any>>(actionType: T) =>
  z.object({
    id: z.string(),
    name: z.string(),
    semanticMap: z.string(),
    plan: z.object({
      id: z.string(),
      name: z.string(),
      actions: z.array(actionType).nonempty(),
    }),
  });

const MissionPlanValidator = MissionCoreValidator(
  MissionActionValidator
).extend({
  timeStamp: z.string().nullable(),
});

export const MissionPlansValidator = z.array(MissionPlanValidator);

const MissionStatusCodeValidator = z.enum([
  "NEW",
  "SENT",
  "RECEIVED",
  "IN_PROGRESS",
  "FINISHED",
  "FAILURE",
  "PAUSED",
  "CANCEL_PENDING",
  "CANCEL_SENT",
  "CANCELED",
  "BLOCKED_BY_MANUAL",
  "CONTINUE",
]);

const VehicleDeploymentValidator = MissionCoreValidator(
  MissionActionDeployedValidator
).extend({
  status: MissionStatusCodeValidator,
  deployedAt: z.string(),
  missionSource: z.string(),
  startedExecutionAt: z.string(),
  totalLoops: z.number(),
  totalActions: z.number(),
  passedLoops: z.number(),
  passedActions: z.number(),
  deploymentId: z.string(),
});

const DeploymentStatus = z.enum(["CONTINUE", "CANCEL"]);

export const DeploymentValidator = z.object({
  id: z.string(),
  name: z.string(),
  previous: DeploymentStatus,
  loops: z.number(),
  actions: z.number(),
  vehicleId: z.string().optional(),
  missionPlanId: z.string(),
  stationId: z.string().optional(),
  fleetId: z.string(),
  vehicleDeployment: VehicleDeploymentValidator.optional(),
});

export const VehicleStateValidator = z.object({
  vehicle_id: z.string(),
  t_vehicle_status: z.string().nullable(),
  t_web_status: z.string().nullable(),
  t_virtual_bumper_status: z.string().nullable(),
  t_long: z.number().nullable(),
  t_lat: z.number().nullable(),
  t_drive_mode: z.string().nullable(),
  last_contact: z.string().nullable(),
  online_status: z.string().nullable(),
  mission_status: z.string().nullable(),
  is_synchronizing: z.boolean().nullable(),
});

export const DeploymentsValidator = z.array(DeploymentValidator);
export const VehicleStatesValidator = z.array(VehicleStateValidator);

const UserImageValidator = z.object({
  id: z.string(),
  link: z.string(),
});

const DashboardLinkValidator = z.object({
  linkName: z.string(),
  linkUrl: z.string(),
});

export const UserValidator = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  companyName: z.string().nullable(),
  image: UserImageValidator.nullish(),
  isActive: z.boolean(),
  companyId: z.string().nullable(),
  role: z.number().nullable(),
  status: z.string(),
  dashboardLinks: z.array(DashboardLinkValidator).optional(),
});
