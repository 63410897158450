import styled from "styled-components";

export const MissionDetailsBodyWrapperStyled = styled.div`
  height: calc(100% - 154px);
  width: 100%;
  overflow: auto;
`;

export const LoopsBodyStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 32px;
`;

export const CounterLoopsBodyStyled = styled.div`
  display: flex;
  gap: 8px;
  color: #1b1b1b;
  line-height: 128%;
  padding-bottom: 16px;
`;

export const CounterLoopsStyled = styled.div`
  color: #606060;
`;

export const DividerLoopsCountersStyled = styled.div`
  width: 4px;

  svg {
    height: 100%;
    width: 4px;

    rect {
      fill: #d1d1d1;
    }
  }
`;

export const StopsBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StopRowStyled = styled.div`
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 16px;
`;

type StopStatusProps = {
  isStopReached: boolean;
};

export const StopNameBodyStyled = styled.div<StopStatusProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #172a3f;
  font-size: 12px;

  svg {
    path {
      fill: ${(props) => props.isStopReached && "#55b8b3"};
      stroke: ${(props) => props.isStopReached && "#bbe3e1"};
    }
  }
`;

export const StopActionsStyled = styled.div`
  color: #1b1b1b;
  font-size: 14px;
`;

export const ProgressBarStyled = styled.div<StopStatusProps>`
  position: relative;
  height: 26px;
  left: 10.5px;
  opacity: 0.4;
  border-left: ${(props) =>
    props.isStopReached ? "1px solid #172a3f" : "1px dotted #253f73"};
  margin: -6px 0;
`;
