import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { getIsAdmin } from "Slices/currentUser";
import { getIsMissionOperator } from "Slices/currentUser/selectors";
import { ChildrenProps } from "Utils/types";
import { getMissionUpdatesFlag } from "Slices/flags/selectors";
import {
  LIVE_MISSIONS_PATH,
  MISSIONS_PATH,
  QUEUE_MISSIONS_PATH,
} from "./AuthorizedRouter";
import { MissionPageUpdates } from "Slices/flags";

export const OnlyForAdminGuard = ({ children }: ChildrenProps) => {
  const isUserAdmin = useSelector(getIsAdmin);

  if (!isUserAdmin) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export const NotForMissionOperatorGuard = ({ children }: ChildrenProps) => {
  const isMissionOperator = useSelector(getIsMissionOperator);

  if (isMissionOperator) return <Navigate to="/operator" />;

  return <>{children}</>;
};

export const MissionsPageGuard = ({ children }: ChildrenProps) => {
  const missionUpdatesFlag = useSelector(getMissionUpdatesFlag);
  const { pathname } = useLocation();

  if (
    [MISSIONS_PATH].includes(pathname) &&
    missionUpdatesFlag === MissionPageUpdates.enabledNewMissionPages
  )
    return <Navigate to={LIVE_MISSIONS_PATH} />;

  if (
    [LIVE_MISSIONS_PATH, QUEUE_MISSIONS_PATH].includes(pathname) &&
    missionUpdatesFlag === MissionPageUpdates.enabledOldMissionPage
  )
    return <Navigate to={MISSIONS_PATH} />;

  return <>{children}</>;
};
