import React, { useState, useRef, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AddMissionsDropdownStyled,
  DropdownButtonStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  ButtonContentStyled,
} from "./styles";
import { ReactComponent as SelectIcon } from "Components/common/assets/selectIcon.svg";
import { useOnClickOutside } from "Utils/hooks";
import {
  ADD_MISSION_MODAL,
  DEPLOYED_MISSION_LIST_MODAL,
  toggleModal,
} from "Slices/modals";
import { FMSLogger } from "FMSLogger";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";
import { getCurrentFleetId } from "Slices/missionPlanner";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

export const AddMissionsDropdown = memo(() => {
  const dispatch = useDispatch();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setShowDropdown(false), []);
  useOnClickOutside(ref, handleClickOutside);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const fleetId = useSelector(getCurrentFleetId);

  const handleNewMissions = () => {
    missionPlannerActionsLogger.debug("Add mission modal has been opened");
    dispatch(toggleModal({ type: ADD_MISSION_MODAL, data: { fleetId } }));
    setShowDropdown(false);
  };

  const handleMissionList = () => {
    missionPlannerActionsLogger.debug("Mission list has been opened");
    dispatch(
      toggleModal({ type: DEPLOYED_MISSION_LIST_MODAL, data: { fleetId } })
    );
    setShowDropdown(false);
  };

  return (
    <AddMissionsDropdownStyled ref={ref}>
      <DropdownButtonStyled
        onClick={() => setShowDropdown(!showDropdown)}
        active={showDropdown}
      >
        <ButtonContentStyled>Add to Queue</ButtonContentStyled>
        <SelectIcon />
      </DropdownButtonStyled>
      {showDropdown && (
        <DropdownContentStyled>
          <DropdownItemStyled onClick={handleNewMissions}>
            New Mission
          </DropdownItemStyled>
          <DropdownItemStyled onClick={handleMissionList}>
            Mission List
          </DropdownItemStyled>
        </DropdownContentStyled>
      )}
    </AddMissionsDropdownStyled>
  );
});
