import {
  fetchMissions,
  cancelLiveAndQueueMission,
  changeMissionOrder,
} from "./actions";
import {
  getMissionsPageFleetId,
  getMissions,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
  getMissionById,
} from "./selectors";
import MissionsQueueSlice from "./reducers";

// actions
export const {
  setMissions,
  setMissionsPageFleetId,
  updateMissions,
  resetMissions,
  reorderMissions,
  pauseMissionsUpdates,
  continueMissionsUpdates,
  resetMissionsUpdatesStorage,
  resetMissionsPageData,
} = MissionsQueueSlice.actions;

// async actions
export { fetchMissions, cancelLiveAndQueueMission, changeMissionOrder };

// selectors
export {
  getMissionsPageFleetId,
  getMissions,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
  getMissionById,
};

// reducer
export default MissionsQueueSlice.reducer;

// constants
export const UNASSIGNED_MISSIONS_KEY = "unassigned";
