import styled from "styled-components";
import GoogleLogin from "react-google-login";

export const GoogleLoginButtonStyled = styled(GoogleLogin)`
  border-radius: 8px !important;
  border: 1px solid rgba(30, 36, 50, 0.23) !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 44px !important;
  transition: border 0.2s !important;
  gap: 8px !important;
  padding: 8px 24px !important;
  width: 100% !important;
  margin-top: 24px;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;

  svg {
    margin: -4px !important;
    height: 100%;
  }

  &:focus {
    border: 2px solid #8fbdff !important;
  }
`;
