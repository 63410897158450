import React, { FC } from "react";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";

import { GoogleLoginButtonStyled } from "./styles";

type GoogleLoginButtonProps = {
  clientId: string;
  buttonText: string;
  onSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => Promise<void>;
  onFailure: (e: unknown) => void;
};

export const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({
  clientId,
  buttonText,
  onSuccess,
  onFailure,
}) => (
  <GoogleLoginButtonStyled
    clientId={clientId}
    buttonText={buttonText}
    onSuccess={onSuccess}
    onFailure={onFailure}
  />
);
