import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { FleetPicker } from "../../../common/FleetPicker";

import { FleetPickerPaddingStyled } from "./styles";
import { setMissionsPageFleetId } from "Slices/missionsPage";
import { storageKeys } from "Utils/localStorageUtils";

export const FleetPickerDropdown = memo(() => {
  const dispatch = useDispatch();

  const handleSelectedFleet = useCallback((fleetId: string | null) => {
    if (fleetId) {
      localStorage.setItem(storageKeys.MISSIONS_SAVED_FLEET_ID, fleetId);
    }
    dispatch(setMissionsPageFleetId(fleetId));
  }, []);

  const initFleetId =
    localStorage.getItem(storageKeys.MISSIONS_SAVED_FLEET_ID) ?? "";

  return (
    <FleetPickerPaddingStyled>
      <FleetPicker
        initFleetId={initFleetId}
        handleSelectedFleet={handleSelectedFleet}
      />
    </FleetPickerPaddingStyled>
  );
});
