import React, { memo } from "react";
import { Outlet } from "react-router-dom";

import {
  MissionsContainerStyled,
  MissionsListContentStyled,
} from "Components/MissionsPage/styles";
import { AsideFilters } from "Components/MissionsPage/components/AsideFilters/AsideFilters";
import { Header } from "Components/MissionsPage/components/Header/Header";
import { SearchContextHelper } from "Components/MissionsPage/SearchContextHelper";

export const Missions = memo(() => {
  return (
    <MissionsContainerStyled>
      <AsideFilters />
      <MissionsListContentStyled>
        <SearchContextHelper>
          <Header />
          <Outlet />
        </SearchContextHelper>
      </MissionsListContentStyled>
    </MissionsContainerStyled>
  );
});
