import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FMSLogger } from "FMSLogger";
import { NAVIGATION_BAR_ACTIONS } from "Utils/constants";
import { closeAnyModal, getIsModalOpen } from "Slices/modals";
import {
  getCurrentUserCompanyName,
  getCurrentUserRole,
} from "Slices/currentUser";
import { LinksContainer } from "./components/LinksContainer";
import { HelpDropdown } from "./components/HelpDropdown";
// import Notifications from "./components/Notifications";
import { UserInfo } from "./components/UserInfo";
import { SecondaryNavBarAssets } from "./components/SecondaryNavBar/SecondaryNavBarAssets";
import { SecondaryNavBarMap } from "./components/SecondaryNavBar/SecondaryNavBarMap";

import { ReactComponent as LogoSvg } from "./assets/logo-short.svg";
import {
  NavigationBarStyled,
  LogoContainerStyled,
  ContentContainerStyled,
  NavigationBarContainerStyled,
} from "./styles";
import { SecondaryNavBarMissions } from "Components/NavigationBar/components/SecondaryNavBar/SecondaryNavBarMissions";

const navigationBarActionsLogger = FMSLogger.byPrefix(NAVIGATION_BAR_ACTIONS);

export const NavigationBar = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isModalOpen: boolean = useSelector(getIsModalOpen);

  const role = useSelector(getCurrentUserRole);
  const companyName = useSelector(getCurrentUserCompanyName);

  const isUserRecognized = useMemo(() => {
    return Boolean(companyName && role);
  }, [companyName, role]);

  const closeModal = useCallback(() => {
    if (!isModalOpen) return;
    dispatch(closeAnyModal());
  }, [isModalOpen]);

  const [isSecondaryNavAssetsShown, setIsSecondaryNavAssetsShown] =
    useState(false);
  const [isSecondaryNavMapShown, setIsSecondaryNavMapShown] = useState(false);
  const [isSecondaryNavMissionsShown, setIsSecondaryNavMissionsShown] =
    useState(false);

  return (
    <NavigationBarContainerStyled>
      <NavigationBarStyled onClick={closeModal}>
        <ContentContainerStyled>
          <LogoContainerStyled
            data-test-id="home"
            isActive={isUserRecognized}
            onClick={() => {
              if (isUserRecognized) {
                navigationBarActionsLogger.debug(
                  "go to main page via navigation bar"
                );
                navigate("/");
              } else void 0;
            }}
          >
            <LogoSvg />
          </LogoContainerStyled>
          <LinksContainer
            setIsSecondaryNavAssetsShown={setIsSecondaryNavAssetsShown}
            setIsSecondaryNavMapShown={setIsSecondaryNavMapShown}
            setIsSecondaryNavMissionsShown={setIsSecondaryNavMissionsShown}
          />
        </ContentContainerStyled>
        <ContentContainerStyled>
          <HelpDropdown />
          {/* <Notifications /> */}
          <UserInfo />
        </ContentContainerStyled>
      </NavigationBarStyled>
      <SecondaryNavBarAssets
        isSecondaryNavAssetsShown={isSecondaryNavAssetsShown}
        setIsSecondaryNavAssetsShown={setIsSecondaryNavAssetsShown}
      />
      <SecondaryNavBarMap
        isSecondaryNavMapShown={isSecondaryNavMapShown}
        setIsSecondaryNavMapShown={setIsSecondaryNavMapShown}
      />
      <SecondaryNavBarMissions
        isSecondaryNavMissionsShown={isSecondaryNavMissionsShown}
        setIsSecondaryNavMissionsShown={setIsSecondaryNavMissionsShown}
      />
    </NavigationBarContainerStyled>
  );
});
