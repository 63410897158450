import styled, { css } from "styled-components";

type DraggableContainerProps = {
  isNoMissions: boolean;
  isShowMissions: boolean;
};

export const DraggableContainerStyled = styled.div<DraggableContainerProps>`
  min-height: ${(props) => props.isNoMissions && "50px"};
  margin-top: ${(props) => props.isShowMissions && "-15px"};
`;

export const DraggableItemStyled = styled.div`
  display: flex;
  height: 64px;
  border: 1px solid #e9e9e9;
  margin-top: -1px;
  background-color: #ffffff;

  // remove to enable dnd
  /* &:hover {
    background: #f4f4f4;
  }

  &:active {
    background: #eef8f8;
    border-width: 1px 1px 1px 0;
    box-shadow: inset 2px 0 #6b9e9c;
  } */
`;

const VehicleTitleContainerActiveStyled = css`
  background: #eef8f8;
  box-shadow: inset 2px 0 #3f7f7d;
  outline: none;
`;

type VehicleGroupContainerProps = {
  isShowMissions: boolean;
};

export const VehicleGroupContainerStyled = styled.div<VehicleGroupContainerProps>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  outline: 1px solid #e9e9e9;
  background: ${(props) => (props.isShowMissions ? "#e9e9e9" : "none")};
  color: ${(props) => (props.isShowMissions ? "##1b1b1b" : "#606060")};

  // remove to enable dnd
  /* &:hover {
    background: ${(props) => (props.isShowMissions ? "e9e9e9" : "#f4f4f4")};
    color: #1b1b1b;
    box-shadow: ${(props) =>
    props.isShowMissions ? "none" : "inset 2px 0 #1b1b1b"};

    svg {
      &:last-child {
        path {
          fill: #1b1b1b;
        }
      }
    }
  }

  &:active {
    ${(props) => !props.isShowMissions && VehicleTitleContainerActiveStyled};
  } */
`;

export const VehicleTitleContainerStyled = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

type SelectIconBodyProps = {
  isShowMissions: boolean;
};

export const SelectIconBodyStyled = styled.div<SelectIconBodyProps>`
  height: 24px;

  svg {
    position: relative;
    top: ${(props) => (props.isShowMissions ? "2px" : "0px")};
    transform: ${(props) =>
      props.isShowMissions ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: 0.3s;

    path {
      fill: ${(props) => (props.isShowMissions ? "#1b1b1b" : "#606060")};
    }
  }
`;

export const VehicleTitleStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  line-height: 128%;
`;

export const ActiveMissionBodyStyled = styled.div`
  display: flex;
  gap: 12px;
`;

export const ActiveMissionStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  color: #3f7f7d;
  font-size: 14px;
  line-height: 140%;
`;
type CounterMissionProps = {
  isShowMissions: boolean;
};

export const CounterMissionsContainerStyled = styled.div<CounterMissionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${(props) => (props.isShowMissions ? "#1b1b1b" : "#606060")};
  text-align: center;
  font-family: "Readex Pro", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
`;

export const CounterStyled = styled.div<CounterMissionProps>`
  height: 20px;

  svg {
    path {
      stroke: ${(props) => (props.isShowMissions ? "#3f7f7d" : "#a4a4a4")};
    }
  }
`;

export const CounterValueStyled = styled.div<CounterMissionProps>`
  position: relative;
  bottom: 20px;
  color: ${(props) => (props.isShowMissions ? "#3f7f7d" : "#a4a4a4")};
  text-align: center;
  font-size: 10px;
  line-height: 120%;
`;
