import { memo, useContext, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchMissions,
  updateMissions,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
  resetMissionsUpdatesStorage,
  getMissionsPageFleetId,
} from "Slices/missionsPage";
import { MISSIONS_WS, WS_SPINNER_LOGS } from "Utils/constants";
import { useFMSWebsocket } from "Utils/webSocketUtils";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:Missions`);

export const MissionsController = memo(() => {
  const currentFleetId = useSelector(getMissionsPageFleetId);

  const isMissionsUpdatesPaused = useSelector(getIsMissionsUpdatesPaused);
  const missionsUpdatesStorage = useSelector(getMissionsUpdatesStorage);
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  useEffect(() => {
    if (currentFleetId) {
      wsSpinnerLogs.debug("new fleet id spinner on");
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug("no fleet id spinner off");
      setIsWSConnecting?.(false);
    }
  }, [currentFleetId]);

  useFMSWebsocket({
    url: "/fleets/mission_queue/",
    subscriptionData: currentFleetId,
    loggerFlag: MISSIONS_WS,
    getDataForSubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    handleInitialMessage: () => {
      wsSpinnerLogs.debug("create message spinner off");
      setIsWSConnecting?.(false);
    },
    handleMessage: (data) => {
      const shouldIgnoreUpdate = data?.mission?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      if (typeof data?.missions === "object") {
        dispatch(updateMissions(data.missions));
      } else {
        dispatch(updateMissions(data));
      }
    },
  });

  // get mission queue if filters has been changed
  useEffect(() => {
    if (!currentFleetId) return;
    dispatch(
      fetchMissions({
        currentFleetId,
      })
    );
  }, [currentFleetId]);

  // update missions from storage
  useEffect(() => {
    if (
      isMissionsUpdatesPaused !== false ||
      missionsUpdatesStorage.length === 0
    )
      return;

    missionsUpdatesStorage.forEach((update) => {
      dispatch(updateMissions({ data: update }));
    });
    dispatch(resetMissionsUpdatesStorage());
  }, [isMissionsUpdatesPaused, missionsUpdatesStorage]);

  return null;
});
