import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import { RowData } from "Components/NewList/types";
import { reorderMissions, setMissions } from ".";

export const fetchMissions = createAsyncThunk<
  void,
  {
    currentFleetId: string;
  }
>(
  "fetchMissions",
  async (
    data: {
      currentFleetId: string;
    },
    thunkAPI
  ) => {
    try {
      // TODO implement http request
      thunkAPI.dispatch(setMissions(mockMissionsData));
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const cancelLiveAndQueueMission = createAsyncThunk<
  void,
  { missionId: string; vehicleId: string }
>(
  "missionPage/cancel",
  async (data: { missionId: string; vehicleId: string }) => {
    try {
      console.log("Implement cancel", data);
      // TODO Change endpoint after completing work on the backend
      // await API.post(
      //   `vehicles/${data.vehicleId}/mission_queue/${data.missionId}/cancel/`
      // );
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const changeMissionOrder = createAsyncThunk<
  void,
  {
    missionId: string;
    order: number;
    cancelAction: () => RowData[];
    newMissionsOrder: RowData[];
  }
>(
  "missionQueue",
  async (
    data: {
      missionId: string;
      order: number;
      cancelAction: () => RowData[];
      newMissionsOrder: RowData[];
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      reorderMissions(data.newMissionsOrder.map((item) => item.id))
    );
    try {
      await API.patch(`fleets/mission_queue/${data.missionId}/`, {
        order: data.order,
      });
      toast.success("Mission reordered successfully");
    } catch (error) {
      const oldItems = data.cancelAction();
      thunkAPI.dispatch(reorderMissions(oldItems.map((item) => item.id)));

      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// TODO remove after real http request will be implemented
const mockMissionsData = [
  {
    id: "999c69bb-b345-42e4-93d3-31e9bsdfkjdkfkd",
    order: 1429,
    timeStamp: "2023-11-07T21:45:41.717777Z",
    name: "unassigned",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: null,
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "80a5eff3-7af4-48d2-9b30-03c846627038",
        name: "A5",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "fa5c3b1b-6d41-4f6d-98fa-48d175c70464",
        name: "B1",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "e6410344-2001-44da-b93e-79516f389dcf",
        name: "A1",
        hitchType: 1,
        duration: -1.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
    ],
    mission: "95f65aae-d360-444a-bae5-c66c0db3a3a3",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "888c69bb-b345-42e4-93d3-31e9bsdfkjsdkkj",
    order: 1428,
    timeStamp: "2023-11-07T21:45:41.717777Z",
    name: "unassigned 2",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: null,
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "80a5eff3-7af4-48d2-9b30-03c846627038",
        name: "A5",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "fa5c3b1b-6d41-4f6d-98fa-48d175c70464",
        name: "B1",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "e6410344-2001-44da-b93e-79516f389dcf",
        name: "A1",
        hitchType: 1,
        duration: -1.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
    ],
    mission: "95f65aae-d360-444a-bae5-c66c0db3a3a3",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "f944d5fc-a992-4053-bca2-ba407f29a813",
    order: 1427,
    timeStamp: "2023-11-07T21:53:21.539261Z",
    name: "Hitch Test 2",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 2,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "0e8f3e0d-6d69-446f-b0d0-7efaa4115078",
        name: "A5",
        hitchType: 0,
        duration: 5.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "f944d5fc-a992-4053-bca2-ba407f29a813",
      },
      {
        id: "8d7fb999-5c3e-41ba-a2ba-aa97edddb86b",
        name: "B1",
        hitchType: 2,
        duration: 5.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "f944d5fc-a992-4053-bca2-ba407f29a813",
      },
      {
        id: "0a941f22-d57c-4505-b16f-e5dfd14ac4a2",
        name: "A1",
        hitchType: 2,
        duration: -1.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "f944d5fc-a992-4053-bca2-ba407f29a813",
      },
      {
        id: "72ee10db-b007-4675-9177-cbc48ca6dc03",
        name: "B2",
        hitchType: 0,
        duration: -1.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "f944d5fc-a992-4053-bca2-ba407f29a813",
      },
    ],
    mission: "95f65aae-d360-444a-bae5-c66c0db3a3a3",
    totalLoops: 3,
    totalStops: 4,
    passedLoops: 1,
    passedStops: 2,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "620c69bb-b345-42e4-93d3-31e9b854a77e",
    order: 1426,
    timeStamp: "2023-11-07T21:45:41.717777Z",
    name: "Hitch Test 2",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "80a5eff3-7af4-48d2-9b30-03c846627038",
        name: "A5",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "fa5c3b1b-6d41-4f6d-98fa-48d175c70464",
        name: "B1",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
      {
        id: "e6410344-2001-44da-b93e-79516f389dcf",
        name: "A1",
        hitchType: 1,
        duration: -1.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "620c69bb-b345-42e4-93d3-31e9b854a77e",
      },
    ],
    mission: "95f65aae-d360-444a-bae5-c66c0db3a3a3",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "d60cba40-c51e-4464-8a50-1ac2f4de13a8",
    order: 1425,
    timeStamp: "2023-11-07T21:29:02.612276Z",
    name: "Unhitch 3",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "1117979f-cef1-413a-9fa3-b48bca6e7195",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "d60cba40-c51e-4464-8a50-1ac2f4de13a8",
      },
      {
        id: "72ee10db-b007-4675-9177-cbc48ca6dc03",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "d60cba40-c51e-4464-8a50-1ac2f4de13a8",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 1,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "9dbecae5-b896-4268-b407-b40cd7df49e9",
    order: 1424,
    timeStamp: "2023-11-07T20:58:02.246885Z",
    name: "Hitch Test 2",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "bd6f1206-ff1f-4045-8f99-2b029705f33b",
        name: "A5",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "9dbecae5-b896-4268-b407-b40cd7df49e9",
      },
      {
        id: "9f23c63d-0b1a-4ba2-a514-5d5229ba046c",
        name: "B1",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "9dbecae5-b896-4268-b407-b40cd7df49e9",
      },
      {
        id: "daf900d7-640b-450a-8000-d4ef6cdfde6f",
        name: "A1",
        hitchType: 1,
        duration: -1.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "9dbecae5-b896-4268-b407-b40cd7df49e9",
      },
    ],
    mission: "95f65aae-d360-444a-bae5-c66c0db3a3a3",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 1,
    passedStops: 1,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "2f165906-3e34-42c1-8551-ef41ffd9a151",
    order: 1423,
    timeStamp: "2023-11-07T20:26:11.681726Z",
    name: "Hitch Test 1",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "3d66fbe5-d2c0-4a5a-bfbb-034af7e11a30",
        name: "A5",
        hitchType: 1,
        duration: 10.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "2f165906-3e34-42c1-8551-ef41ffd9a151",
      },
      {
        id: "9cb27e2b-dbf2-4c8e-acd7-4c9723b6bae6",
        name: "B1",
        hitchType: 1,
        duration: 10.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "2f165906-3e34-42c1-8551-ef41ffd9a151",
      },
      {
        id: "7b2aa171-e0f7-4a89-8a15-ed2b652dc8fa",
        name: "A1",
        hitchType: 1,
        duration: 10.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "2f165906-3e34-42c1-8551-ef41ffd9a151",
      },
    ],
    mission: "04d58795-5750-4c37-b2bc-09d2c01fe8ce",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 1,
    passedStops: 1,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "551895fe-b159-449f-9d33-2434dd898c8e",
    order: 1422,
    timeStamp: "2023-11-07T17:24:38.893365Z",
    name: "Hitch Test 1",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "f9fd2986-6218-46f3-8989-030cbb4f555f",
        name: "A5",
        hitchType: 1,
        duration: 10.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "551895fe-b159-449f-9d33-2434dd898c8e",
      },
      {
        id: "84e68c90-13e9-4f73-a508-b9b2ebb761da",
        name: "B1",
        hitchType: 1,
        duration: 10.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "551895fe-b159-449f-9d33-2434dd898c8e",
      },
      {
        id: "ad64c956-d3cb-4d19-8ce0-58e883fd655a",
        name: "A1",
        hitchType: 1,
        duration: 10.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "551895fe-b159-449f-9d33-2434dd898c8e",
      },
    ],
    mission: "04d58795-5750-4c37-b2bc-09d2c01fe8ce",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 1,
    passedStops: 3,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "daae03e3-5b13-4fcb-b17c-369c3f768618",
    order: 1421,
    timeStamp: "2023-11-07T17:22:01.985959Z",
    name: "Hitch Test 1",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "11017ceb-db33-4d2c-b4fa-44b50df1d3a1",
        name: "A5",
        hitchType: 1,
        duration: 10.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "daae03e3-5b13-4fcb-b17c-369c3f768618",
      },
      {
        id: "24edcbf1-987c-42f7-85b8-0edd69338cac",
        name: "B1",
        hitchType: 1,
        duration: 10.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "daae03e3-5b13-4fcb-b17c-369c3f768618",
      },
      {
        id: "c1e6cac0-75c8-4847-9443-ef8d1b342ac4",
        name: "A1",
        hitchType: 1,
        duration: 10.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "daae03e3-5b13-4fcb-b17c-369c3f768618",
      },
    ],
    mission: "04d58795-5750-4c37-b2bc-09d2c01fe8ce",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "19f77c95-8e18-4bcc-8373-b89c95062605",
    order: 1420,
    timeStamp: "2023-11-07T17:08:26.037131Z",
    name: "Hitch Test 1",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "baf9f88a-fc88-453a-87a1-1caef2ca147d",
        name: "A5",
        hitchType: 1,
        duration: 10.0,
        order: 1,
        latitude: 37.47529604936037,
        longitude: -122.15084298227612,
        originalStop: "62e30690-081e-41ff-8056-2548e3c66b8c",
        mission: "19f77c95-8e18-4bcc-8373-b89c95062605",
      },
      {
        id: "da0104b8-d72e-409c-b2c7-0b7473ce144f",
        name: "B1",
        hitchType: 1,
        duration: 10.0,
        order: 2,
        latitude: 37.47526604999239,
        longitude: -122.15094452288065,
        originalStop: "246fe683-2521-4a00-8e33-93113cac5bd0",
        mission: "19f77c95-8e18-4bcc-8373-b89c95062605",
      },
      {
        id: "0223e940-f311-484b-a333-164022c6c212",
        name: "A1",
        hitchType: 1,
        duration: 10.0,
        order: 3,
        latitude: 37.47531173126178,
        longitude: -122.150965007054,
        originalStop: "9bd7305c-75c6-4cd6-876a-44a3a5a2b612",
        mission: "19f77c95-8e18-4bcc-8373-b89c95062605",
      },
    ],
    mission: "04d58795-5750-4c37-b2bc-09d2c01fe8ce",
    totalLoops: 1,
    totalStops: 3,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "6f5480ed-0706-42c4-9dd5-8f5731531509",
    order: 1419,
    timeStamp: "2023-11-02T17:02:55.056835Z",
    name: "Unhitch 3",
    status: "SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000007",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "74db32bb-54ef-4323-aa04-26cf0b2bfa70",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "6f5480ed-0706-42c4-9dd5-8f5731531509",
      },
      {
        id: "787afd3e-71c6-49a2-a802-dcd57618bdef",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "6f5480ed-0706-42c4-9dd5-8f5731531509",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "7c195f8f-8bb8-40c3-af95-7ae0ec135338",
    order: 1418,
    timeStamp: "2023-11-02T17:02:35.213866Z",
    name: "Unhitch 3",
    status: "CANCEL_SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000007",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "c9da4eab-508a-4659-87ba-465a71bf4940",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "7c195f8f-8bb8-40c3-af95-7ae0ec135338",
      },
      {
        id: "8f7c5433-f4e6-4a8b-934a-d35b66eaf450",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "7c195f8f-8bb8-40c3-af95-7ae0ec135338",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "39183021-75eb-4495-8d9d-39ac9ae2159b",
    order: 1417,
    timeStamp: "2023-11-01T23:25:09.909748Z",
    name: "Unhitch 3",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "b1cea989-ba59-4c1c-9d87-e5f12edd2c26",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "39183021-75eb-4495-8d9d-39ac9ae2159b",
      },
      {
        id: "8c0d754e-68ca-4928-8d04-9f75ba1273bd",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "39183021-75eb-4495-8d9d-39ac9ae2159b",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 1,
    passedStops: 2,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "4d7701e8-824f-47b3-8541-9fa4e1088d7a",
    order: 1416,
    timeStamp: "2023-11-01T23:22:20.144783Z",
    name: "Unhitch 3",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "3c0e8406-3b72-488f-831c-54012166b513",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "4d7701e8-824f-47b3-8541-9fa4e1088d7a",
      },
      {
        id: "329b70c3-3ba1-4457-9119-fade3ebceb8f",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "4d7701e8-824f-47b3-8541-9fa4e1088d7a",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 1,
    passedStops: 2,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "c9a16eb1-10ef-4fd8-a5fa-cee0966a1a9e",
    order: 1415,
    timeStamp: "2023-11-01T23:03:54.820435Z",
    name: "Unhitch 3",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "c6026594-8e78-40aa-91e6-a9d6a39f0a96",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "c9a16eb1-10ef-4fd8-a5fa-cee0966a1a9e",
      },
      {
        id: "62db7fd2-3a39-44d6-8253-0e4f9edc9e83",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "c9a16eb1-10ef-4fd8-a5fa-cee0966a1a9e",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 1,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "31833fa5-3ef1-4001-b862-019d56b30d03",
    order: 1414,
    timeStamp: "2023-11-01T23:02:49.836147Z",
    name: "Unhitch 3",
    status: "NEW",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "af36cf6e-9417-4c12-a747-f8279e07e67f",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "31833fa5-3ef1-4001-b862-019d56b30d03",
      },
      {
        id: "5d4f9390-ac47-421e-8313-74b51a352290",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "31833fa5-3ef1-4001-b862-019d56b30d03",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 1,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "24faae63-8706-4a03-85a8-33f071134357",
    order: 1413,
    timeStamp: "2023-11-01T22:58:44.220750Z",
    name: "Unhitch Test - w/o unhitc",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "7103ef0c-fa87-43c0-a8f0-3528b584cc8d",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "24faae63-8706-4a03-85a8-33f071134357",
      },
      {
        id: "f1b72fec-c334-4b6b-931c-323b05684037",
        name: "Aisle B",
        hitchType: 1,
        duration: 3.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "24faae63-8706-4a03-85a8-33f071134357",
      },
    ],
    mission: "04943078-a2a6-416d-9970-2824f0780584",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 1,
    passedStops: 2,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "556dd40e-91b8-454e-9572-c0dfbedfddd2",
    order: 1412,
    timeStamp: "2023-11-01T22:53:48.296448Z",
    name: "Unhitch 3",
    status: "IN_PROGRESS",
    previous: "Continue",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "f8caba53-4730-4d32-bbdd-d4940e065c9c",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "556dd40e-91b8-454e-9572-c0dfbedfddd2",
      },
      {
        id: "301c13e2-d399-446f-b83a-d6905de51610",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "556dd40e-91b8-454e-9572-c0dfbedfddd2",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 1,
    passedStops: 2,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "c85acffc-4837-4170-9ead-6f9fa10437b7",
    order: 1411,
    timeStamp: "2023-11-01T21:58:00.206819Z",
    name: "Unhitch 3",
    status: "SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "46a021ce-e629-45c1-abd1-2e2cde393c16",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "c85acffc-4837-4170-9ead-6f9fa10437b7",
      },
      {
        id: "35a29241-3c7e-4d05-bc77-77bd8471aaeb",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "c85acffc-4837-4170-9ead-6f9fa10437b7",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "40800863-cbeb-47ff-93e4-41aaf0514461",
    order: 1410,
    timeStamp: "2023-11-01T21:56:34.956430Z",
    name: "Unhitch - Long",
    status: "NEW",
    previous: "Cancel",
    loops: 1,
    vehicle: "8c8e6199-affc-4293-a42f-53251678536d",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "4e72d418-b601-419c-b1fe-63caccb43dc1",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "40800863-cbeb-47ff-93e4-41aaf0514461",
      },
      {
        id: "3d037a37-396b-4238-b31b-8f89a7422200",
        name: "Aisle B",
        hitchType: 1,
        duration: 3.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "40800863-cbeb-47ff-93e4-41aaf0514461",
      },
      {
        id: "20d7fd34-ec8d-4003-a7b7-64714b2251b5",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 3,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "40800863-cbeb-47ff-93e4-41aaf0514461",
      },
      {
        id: "bdd01307-8734-42de-85dd-d1a211eee900",
        name: "Aisle B",
        hitchType: 1,
        duration: 10.0,
        order: 4,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "40800863-cbeb-47ff-93e4-41aaf0514461",
      },
      {
        id: "2b9a962a-6400-4bd8-958a-178056e0fe13",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 5,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "40800863-cbeb-47ff-93e4-41aaf0514461",
      },
    ],
    mission: "ecd6562c-9741-4dd8-8cee-2398471bed61",
    totalLoops: 1,
    totalStops: 5,
    passedLoops: 0,
    passedStops: 3,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "b7cb9eb6-e378-4a2b-bb78-91633992cc05",
    order: 1409,
    timeStamp: "2023-11-01T21:19:01.680920Z",
    name: "Unhitch 3",
    status: "CANCEL_SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000007",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "7a6a6875-795e-4ced-b17d-2526c52490f8",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "b7cb9eb6-e378-4a2b-bb78-91633992cc05",
      },
      {
        id: "2a7e7bc3-d495-4964-9b46-dd64f0e39fb1",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "b7cb9eb6-e378-4a2b-bb78-91633992cc05",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "32158f47-c9b6-40bf-b414-1721abe86470",
    order: 1408,
    timeStamp: "2023-11-01T21:13:31.127894Z",
    name: "Unhitch 3",
    status: "CANCEL_SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000007",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "546cea8d-8d26-4b94-a19a-857dbf5156fd",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "32158f47-c9b6-40bf-b414-1721abe86470",
      },
      {
        id: "0b8f1a20-d5c0-4fe6-9e1f-e8dd40797902",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "32158f47-c9b6-40bf-b414-1721abe86470",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "063c3004-bf85-4348-b386-907ca85284ab",
    order: 1407,
    timeStamp: "2023-11-01T16:36:04.583642Z",
    name: "Unhitch - Long",
    status: "SENT",
    previous: "Cancel",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000008",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "44589093-3534-4381-b4a8-d360972dc376",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "063c3004-bf85-4348-b386-907ca85284ab",
      },
      {
        id: "ce41a2f3-a29b-4eb6-bc49-5b3ae2a6b1d9",
        name: "Aisle B",
        hitchType: 1,
        duration: 3.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "063c3004-bf85-4348-b386-907ca85284ab",
      },
      {
        id: "11271235-60c8-4335-b94d-4923506e39b5",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 3,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "063c3004-bf85-4348-b386-907ca85284ab",
      },
      {
        id: "73e0bd52-6bf2-4626-a590-85c25cc8740c",
        name: "Aisle B",
        hitchType: 1,
        duration: 10.0,
        order: 4,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "063c3004-bf85-4348-b386-907ca85284ab",
      },
      {
        id: "b7acbd9d-215c-4f07-aa93-417bb98e5ea0",
        name: "Aisle A",
        hitchType: 1,
        duration: 3.0,
        order: 5,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "063c3004-bf85-4348-b386-907ca85284ab",
      },
    ],
    mission: "ecd6562c-9741-4dd8-8cee-2398471bed61",
    totalLoops: 1,
    totalStops: 5,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-814ad4706313",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 3",
    status: "SENT",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-814ad4706313",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-814ad4706313",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-123123123123",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-123123123123",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-123123123123",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-1231234345345",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-1231234345345",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-1231234345345",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-488483834",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-488483834",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-488483834",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-98989803234",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-98989803234",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-98989803234",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-82998402394209",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-82998402394209",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-82998402394209",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-98938092384029",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-98938092384029",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-98938092384029",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-998e0rwer9w",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-998e0rwer9w",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-998e0rwer9w",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
  {
    id: "97bdaec1-c285-46b9-aaf0-idfs9fs9df0s9f",
    order: 1406,
    timeStamp: "2023-11-01T16:35:26.233830Z",
    name: "Unhitch 34",
    status: "PAUSED",
    previous: "Continue",
    loops: 1,
    vehicle: "00000000-0000-0000-0000-000000000005",
    default: false,
    data: null,
    deployedAt: "2023-11-01T16:35:26.233830Z",
    missionSource: "OVM",
    stops: [
      {
        id: "432c0feb-e69f-4d82-a31e-0fa32e524294",
        name: "Aisle A",
        hitchType: 1,
        duration: 5.0,
        order: 1,
        latitude: 37.47530248746271,
        longitude: -122.15090184172033,
        originalStop: "2dc75096-aba3-4237-9b95-330193e71e1a",
        mission: "97bdaec1-c285-46b9-aaf0-idfs9fs9df0s9f",
      },
      {
        id: "dc37fa78-f049-47b1-ae5d-bf2b6796e63e",
        name: "Aisle B",
        hitchType: 1,
        duration: 5.0,
        order: 2,
        latitude: 37.475260497125404,
        longitude: -122.15090798307719,
        originalStop: "1cda04cd-2b29-4d25-820e-956d2358aa8b",
        mission: "97bdaec1-c285-46b9-aaf0-idfs9fs9df0s9f",
      },
    ],
    mission: "b1aa4cd4-70a5-4fc7-b065-df9cc28b5f50",
    totalLoops: 1,
    totalStops: 2,
    passedLoops: 0,
    passedStops: 0,
    fleet: null,
    startedExecutionAt: "2023-11-01T16:35:26.233830Z",
  },
];
