import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FMSLogger } from "FMSLogger";

import {
  closeAnyModal,
  DEPLOYED_MISSION_LIST_MODAL,
  getModalData,
  toggleModal,
} from "Slices/modals";
import {
  fetchDeployMissionPlan,
  fetchPatchMissionPlan,
  fetchPostMissionPlan,
} from "Slices/missionPlans";
import { getCurrentSemanticMapId } from "Slices/appState";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import {
  ModalContentStyled,
  HeaderStyled,
  CancelButtonStyled,
  SuccessButtonStyled,
  FooterStyled,
  ContentStyled,
} from "Components/common/ModalComponent/styles";
import { AssignModal } from "Components/Modals/AddEditMissionModal/components/AssignModal";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import { Input } from "../ListDataPagesModals/components/Input";
import { LabelField } from "../ListDataPagesModals/components/LabelField";

import { ActionsTable } from "./components/ActionsTable/ActionsTable";
import { ConfirmMissionsModal } from "./components/ConfirmMissionsModal/ConfirmMissionsModal";
import { getIsMissionActionsValid } from "Components/Modals/AddEditMissionModal/utils";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionModalData = Partial<MissionPlan> & {
  vehicleId?: string;
  vehicleName?: string;
  fleetId: string;
};

export const AddEditMissionModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData) as unknown as MissionModalData;
  const vehiclesUpdateData = useSelector(getVehiclesUpdateData);
  const semanticMap = useSelector(getCurrentSemanticMapId) || "";
  const isEditMission = Boolean(modalData && modalData.id && modalData.plan);
  const { vehicleId, vehicleName, fleetId } = modalData;

  const [name, setName] = useState<string>(
    isEditMission ? String(modalData.name) : ""
  );
  const [missionActions, setMissionActions] = useState<
    Array<MissionAction | MissionActionPatch>
  >(isEditMission ? (modalData.plan as MissionPlan["plan"]).actions : []);
  const [assignModal, setAssignModal] = useState(false);
  const [confirmOverrideMissionData, setConfirmOverrideMissionData] = useState<{
    text: string;
    confirmAction: () => void;
    cancelAction: () => void;
  } | null>(null);

  const addNewMissionPlan = () =>
    dispatch(
      fetchPostMissionPlan({
        name,
        semanticMap,
        plan: {
          name,
          actions: missionActions,
        },
      })
    );

  const successAction = useCallback(() => {
    if (isEditMission) {
      dispatch(
        fetchPatchMissionPlan({
          id: modalData.id as string,
          data: {
            name,
            plan: {
              name,
              actions: missionActions,
            },
          },
        })
      );
    } else {
      addNewMissionPlan();
    }
  }, [isEditMission, missionActions, name, modalData]);

  const saveAndDeployMission = useCallback(
    async (pickedId: string, selectedLoopsValue: number, isFleet = false) => {
      const addedMissionData = (await addNewMissionPlan()) as unknown as {
        payload: MissionPlan;
      };
      const currentDeploy = (missionId: string) => {
        dispatch(
          fetchDeployMissionPlan({
            name,
            fleetId,
            vehicleId: !isFleet ? pickedId : undefined,
            missionPlanId: missionId,
            loops: Number(selectedLoopsValue),
            previous: "CONTINUE",
          })
        );
        handleCloseModal();
      };

      if (
        !isFleet &&
        // Because we do not need to show MissionsModal with override confirmation if we
        // are on /missions page (in /missions page previousMode always in "Continue" state)
        vehiclesUpdateData &&
        vehiclesUpdateData[pickedId] &&
        vehiclesUpdateData[pickedId].mission_status === "IN_PROGRESS"
      ) {
        setConfirmOverrideMissionData({
          text: "This vehicle has a mission in progress. Would you like to cancel the current mission and redeploy this one?",
          confirmAction: () => {
            missionDeploymentActionsLogger.debug(
              "deploy mission with override"
            );
            currentDeploy(addedMissionData.payload.id);
          },
          cancelAction: () => {
            missionDeploymentActionsLogger.debug(
              "cancel mission with override"
            );
            setConfirmOverrideMissionData(null);
          },
        });
        return;
      }
      missionDeploymentActionsLogger.debug("deploy mission");
      if (addedMissionData) {
        currentDeploy(addedMissionData.payload.id);
      }
    },
    [name, missionActions, semanticMap]
  );

  const openConfirmModalAction = useCallback(() => {
    const isMissionActionsValid = getIsMissionActionsValid(missionActions);
    if (!isMissionActionsValid) return;

    missionDeploymentActionsLogger.debug("save new mission");
    setConfirmOverrideMissionData({
      text: "Would you like deploy the new mission?",
      confirmAction: async () => {
        missionDeploymentActionsLogger.debug(
          "save new mission with further deployment"
        );
        setAssignModal(true);
      },
      cancelAction: () => {
        missionDeploymentActionsLogger.debug(
          "save new mission without deployment"
        );
        successAction();
        handleCloseModal();
      },
    });
  }, [missionActions, name, missionActions]);

  const handleCloseModal = useCallback(() => {
    if (isEditMission) {
      dispatch(
        toggleModal({ type: DEPLOYED_MISSION_LIST_MODAL, data: { fleetId } })
      );
    } else {
      dispatch(closeAnyModal());
    }
  }, []);

  const handleMissionWithPickedVehicle = (
    vehicleId: string,
    selectedLoopsValue: number
  ) => {
    saveAndDeployMission(vehicleId, selectedLoopsValue);
  };

  const handleMissionWithPickedFleet = (
    fleetId: string,
    selectedLoopsValue: number
  ) => {
    saveAndDeployMission(fleetId, selectedLoopsValue, true);
  };

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <ModalContentStyled>
        {confirmOverrideMissionData && (
          <ConfirmMissionsModal {...confirmOverrideMissionData} />
        )}
        {assignModal && (
          <AssignModal
            currentFleetId={fleetId}
            currentMissionActions={missionActions}
            vehicleId={vehicleId}
            vehicleName={vehicleName}
            handleVehiclePick={handleMissionWithPickedVehicle}
            handleFleetPick={handleMissionWithPickedFleet}
            cancelAction={handleCloseModal}
          />
        )}
        <HeaderStyled>{`${
          isEditMission ? "Edit" : "Create a New"
        } Mission`}</HeaderStyled>
        <ContentStyled>
          <Input value={name} labelText="Mission Name" changeAction={setName} />
          <LabelField labelText="Actions" />
          <ActionsTable
            missionActions={missionActions}
            setMissionActions={setMissionActions}
          />
        </ContentStyled>
        <FooterStyled>
          <CancelButtonStyled onClick={handleCloseModal}>
            Cancel
          </CancelButtonStyled>
          <SuccessButtonStyled
            isDisabled={false}
            onClick={openConfirmModalAction}
          >
            {isEditMission ? "Save Changes" : "Create Mission"}
          </SuccessButtonStyled>
        </FooterStyled>
      </ModalContentStyled>
    </ModalStyled>
  );
});
