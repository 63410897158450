import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setMissionPlannerData, getMissionPlannerData } from "Slices/appState";
import { getCurrentSemanticMap } from "Slices/semanticMaps";
import { MissionPlannerData } from "Slices/appState/reducers";
import { MapComponent } from "Components/MapComponent";

import { VehiclesList } from "./components/VehiclesList";
import { MissionPlanner } from "./components/MissionPlanner";
import { CallVehicleButton } from "./components/CallVehicle";
import {
  MapWindowStyled,
  SidebarStyled,
  ToggleSatButtonStyled,
  WrapperStyled,
} from "./styles";

export const RealTimeMap = memo(() => {
  const [showSatView, setShowSatView] = useState<boolean>(false);
  const missionPlannerData = useSelector(getMissionPlannerData);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);
  const dispatch = useDispatch();

  const toggleSatView = () => {
    setShowSatView(!showSatView);
  };

  const setMissionPlannerDataAction = (
    missionPlannerData: MissionPlannerData | null
  ) => {
    dispatch(setMissionPlannerData(missionPlannerData));
  };

  return (
    <WrapperStyled>
      {currentSemanticMap && (
        <SidebarStyled>
          <VehiclesList
            setMissionPlannerData={setMissionPlannerDataAction}
            currentSemanticMap={currentSemanticMap}
          />
          <CallVehicleButton />
        </SidebarStyled>
      )}
      <MapWindowStyled>
        <MapComponent showSatView={showSatView} />
        <ToggleSatButtonStyled onClick={toggleSatView}></ToggleSatButtonStyled>
      </MapWindowStyled>
      {missionPlannerData && (
        <MissionPlanner
          missionPlannerData={missionPlannerData}
          setMissionPlannerData={setMissionPlannerDataAction}
        />
      )}
    </WrapperStyled>
  );
});
