import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  FC,
} from "react";
import { useSelector } from "react-redux";

import { ButtonComponent } from "../common/ButtonComponent/Button.component";
import { CheckboxComponent } from "../common/CheckBoxComponent/CheckBox.component";
import { DropdownComponent } from "../common/DropdownComponent";
import { useOnClickOutside } from "Utils/hooks";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import {
  getStopsByCurrentSemanticMap,
  getStopsByCurrentSemanticMapDict,
} from "Slices/stops";
import { isUnhitchEnable } from "Slices/flags";
import { MissionsModal } from "./MissionsModal";
import { AssignModal } from "./AssignModal";
import { sortCollectionByNumberField } from "Utils/collectionUtils";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";
import { HelperText } from "Components/common/HelperText/HelperText";
import { HitchType } from "../../store/types";

import { ReactComponent as ErrorInfoIcon } from "../common/assets/errorInfoIcon.svg";
import {
  MissionDetailsContainerStyled,
  MissionDetailsLabelStyled,
  MissionDetailsTitleItemStyled,
  MissionDetailsItemStyled,
  InputStyled,
  PlanItemsBodyStyled,
  PlanItemsStyled,
  RemovePlanItemStyled,
  MissionDetailsItemContentStyled,
  TimePlanItemStyled,
  MissionDetailsListStyled,
  ButtonsContainerStyled,
  ErrorMessageStyled,
  StopsDropdownStyled,
  StopsDropdownCurrentStyled,
  StopsDropdownContentStyled,
  PlanItemNameStyled,
  CheckboxBodyStyled,
  HitchTypeStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

const radioData = [
  {
    label: "Add to queue",
    value: "Continue",
  },
  {
    label: "Overwrite existing",
    value: "Cancel",
  },
];
const cancelPrevious = radioData[1].value;

const getFormattedMissionName = (missionName: string) =>
  missionName || `Created ${new Date().toLocaleDateString()}`;

type MissionPlanDetailsProps = {
  editedMissionPlan?: MissionAsset | null;
  cancelAction: () => void;
  addPlanMissionAction?: any;
  patchPlanMissionAction?: any;
  deployPlanMissionAction: (data: MissionAsset) => void;
  vehicleId?: string;
  vehicleName?: string;
  semanticMapId: string | null;
  changeTabAction?: () => void;
  previousMode: string;
};

export const MissionPlanDetails: FC<MissionPlanDetailsProps> = ({
  editedMissionPlan,
  cancelAction,
  addPlanMissionAction,
  patchPlanMissionAction,
  deployPlanMissionAction,
  vehicleId,
  vehicleName,
  semanticMapId,
  changeTabAction = () => void 0,
  previousMode = cancelPrevious,
}) => {
  const stops = useSelector(getStopsByCurrentSemanticMap);
  const isUnhitchFeatureOn = useSelector(isUnhitchEnable);
  const vehiclesUpdateData = useSelector(getVehiclesUpdateData);
  const stopsObject = useSelector(getStopsByCurrentSemanticMapDict);
  const [saveMissionModal, setSaveMissionModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [confirmOverwriteMissionData, setConfirmOverwriteMissionData] =
    useState<{
      text: string;
      confirmAction: () => void;
      cancelAction: () => void;
    } | null>(null);
  const [infinitelyStop, setInfinitelyStop] = useState(true);
  const [isUnhitch, setHitchType] = useState(false);
  const [currentStopItem, setCurrentStopItem] = useState<Stop | null>(null);
  const [stopSeconds, setStopSeconds] = useState("");
  const [missionName, setMissionName] = useState(editedMissionPlan?.name || "");
  const [missionPlanStops, setMissionPlanStops] = useState<MissionPlanStop[]>(
    editedMissionPlan?.stops || []
  );
  const [stopTimeMenuOpened, setStopTimeMenuOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const listRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(
    () => setStopTimeMenuOpened(false),
    []
  );
  useOnClickOutside(dropdownRef, handleClickOutside);

  useEffect(() => {
    if (listRef.current && listRef.current.scrollIntoView) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [missionPlanStops]);

  const disabledAddPlanBtn =
    !(stopSeconds || infinitelyStop) || !currentStopItem;

  const addPlanItem = useCallback(() => {
    if (disabledAddPlanBtn) {
      setErrorMessage(
        "Stop could not be added. Please double check we got your details right."
      );
      return;
    }
    setMissionPlanStops([
      ...missionPlanStops,
      {
        id: currentStopItem.id,
        stopPk: currentStopItem.id,
        duration: infinitelyStop ? -1 : Number(stopSeconds),
        order: missionPlanStops.length + 1,
        hitchType: isUnhitch ? HitchType.UNHITCH : HitchType.UNSET,
      },
    ]);
    setInfinitelyStop(true);
    setHitchType(false);
    setStopSeconds("");
    setCurrentStopItem(null);
    setErrorMessage("");
  }, [
    disabledAddPlanBtn,
    missionPlanStops,
    currentStopItem?.id,
    infinitelyStop,
    stopSeconds,
    isUnhitch,
  ]);

  const removePlanItem = useCallback(
    (indexPlanItem: number) => {
      const missionPlanStopsItems = [...missionPlanStops];
      missionPlanStopsItems.splice(indexPlanItem, 1);
      setMissionPlanStops(missionPlanStopsItems);
    },
    [missionPlanStops]
  );

  const dataJSON = {
    timeStamp: new Date(),
    previous: previousMode,
    stops: [],
    semanticMap: semanticMapId,
  };

  const openModalAction = () => {
    if (missionPlanStops.length === 0) {
      setErrorMessage(
        "Mission failed to deploy. Double check all mission details are right or contact your admin for support."
      );
      return;
    }
    missionDeploymentActionsLogger.debug("save new mission");
    setErrorMessage("");
    setSaveMissionModal(true);
  };

  const saveAndDeployMission = async (
    pickedId: string,
    selectedLoopsValue: number,
    isFleet = false
  ) => {
    const addedMissionData = await addPlanMissionAction({
      ...dataJSON,
      name: getFormattedMissionName(missionName),
      stops: missionPlanStops,
    });
    const currentDeploy = (missionId: string) => {
      deployPlanMissionAction({
        ...dataJSON,
        name: getFormattedMissionName(missionName),
        ...(isFleet ? { fleet: pickedId } : { vehicle: pickedId }),
        stops: missionPlanStops,
        loops: Number(selectedLoopsValue),
        // This "id" will be changed to "mission" field into fetchDeployMission()
        id: missionId,
      } as unknown as MissionAsset);
      changeTabAction?.();
    };
    if (
      !isFleet &&
      // Because we do not need to show MissionsModal with overwrite confirmation if we
      // are on /missions page (in /missions page previousMode always in "Continue" state)
      previousMode !== "Continue" &&
      vehiclesUpdateData &&
      vehiclesUpdateData[pickedId] &&
      vehiclesUpdateData[pickedId].mission_status === "IN_PROGRESS"
    ) {
      setConfirmOverwriteMissionData({
        text: "This vehicle has a mission in progress. Would you like to cancel the current mission and redeploy this one?",
        confirmAction: () => {
          missionDeploymentActionsLogger.debug("deploy mission with overwrite");
          currentDeploy(addedMissionData.payload.id);
        },
        cancelAction: () => {
          missionDeploymentActionsLogger.debug("cancel mission with overwrite");
          setConfirmOverwriteMissionData(null);
        },
      });
      return;
    }
    missionDeploymentActionsLogger.debug("deploy mission");
    currentDeploy(addedMissionData.payload.id);
  };

  const handleMissionWithPickedVehicle = (
    vehicleId: string,
    selectedLoopsValue: number
  ) => {
    saveAndDeployMission(vehicleId, selectedLoopsValue);
  };

  const handleMissionWithPickedFleet = (
    fleetId: string,
    selectedLoopsValue: number
  ) => {
    saveAndDeployMission(fleetId, selectedLoopsValue, true);
  };

  const saveMissionAction = (needDeployMission: boolean) => {
    setErrorMessage("");
    if (needDeployMission) {
      setSaveMissionModal(false);
      setAssignModal(true);
    } else {
      addPlanMissionAction({
        ...dataJSON,
        name: getFormattedMissionName(missionName),
        stops: missionPlanStops,
        timeStamp: null,
      });
      changeTabAction?.();
    }
  };

  const editMissionAction = () => {
    missionDeploymentActionsLogger.debug("save edited mission");
    patchPlanMissionAction?.({
      id: editedMissionPlan?.id,
      data: {
        name: getFormattedMissionName(missionName),
        stops: missionPlanStops,
        previous: previousMode,
        timeStamp: editedMissionPlan?.timeStamp,
      },
    });
    cancelAction();
  };

  const sortedMissionPlanStops = useMemo(
    () => sortCollectionByNumberField(missionPlanStops, "order"),
    [missionPlanStops]
  );

  const HitchStatus = useCallback((hitchType: number) => {
    if (!isUnhitchFeatureOn || hitchType === HitchType.UNSET) return null;
    if (hitchType === HitchType.HITCH)
      return (
        <HitchTypeStyled>
          <ErrorInfoIcon />
        </HitchTypeStyled>
      );
    if (hitchType === HitchType.UNHITCH) {
      return <HitchTypeStyled>Unhitch</HitchTypeStyled>;
    } else return null;
  }, []);

  return (
    <MissionDetailsContainerStyled>
      {!confirmOverwriteMissionData && saveMissionModal && (
        <MissionsModal
          text="Would you like deploy the new mission?"
          confirmAction={() => {
            missionDeploymentActionsLogger.debug(
              "save new mission with further deployment"
            );
            saveMissionAction(true);
          }}
          cancelAction={() => {
            missionDeploymentActionsLogger.debug(
              "save new mission without deployment"
            );
            saveMissionAction(false);
          }}
        />
      )}
      {assignModal && (
        <AssignModal
          currentMissionDataStops={missionPlanStops}
          vehicleId={vehicleId}
          vehicleName={vehicleName}
          handleVehiclePick={handleMissionWithPickedVehicle}
          handleFleetPick={handleMissionWithPickedFleet}
          cancelAction={changeTabAction}
        />
      )}
      {confirmOverwriteMissionData && (
        <MissionsModal {...confirmOverwriteMissionData} />
      )}
      <MissionDetailsTitleItemStyled>
        <MissionDetailsLabelStyled>Mission Name</MissionDetailsLabelStyled>
      </MissionDetailsTitleItemStyled>
      <InputStyled
        type="text"
        maxLength={25}
        placeholder="Mission Name"
        value={missionName}
        onChange={(e) => {
          setMissionName(e.target.value);
        }}
        style={{
          marginLeft: "0",
          marginRight: "0",
          marginBottom: "8px",
        }}
      />
      <HelperText additionalInfo="Mission names are limited to 25 characters" />
      <MissionDetailsTitleItemStyled>
        <MissionDetailsLabelStyled>Plan your stops</MissionDetailsLabelStyled>
      </MissionDetailsTitleItemStyled>
      <MissionDetailsItemContentStyled>
        {sortedMissionPlanStops && sortedMissionPlanStops.length !== 0 && (
          <MissionDetailsListStyled>
            {sortedMissionPlanStops.map(
              ({ duration, hitchType, stopPk }, index) => {
                const name = stopsObject[stopPk]?.name || "No Data";

                return (
                  <PlanItemsBodyStyled key={`${name}-${index}`}>
                    <PlanItemsStyled>
                      <PlanItemNameStyled>{name}</PlanItemNameStyled>
                      <TimePlanItemStyled>
                        {duration === -1 ? "Manual Release" : `${duration} sec`}
                      </TimePlanItemStyled>
                      {HitchStatus(Number(hitchType))}
                    </PlanItemsStyled>
                    <RemovePlanItemStyled onClick={() => removePlanItem(index)}>
                      remove
                    </RemovePlanItemStyled>
                  </PlanItemsBodyStyled>
                );
              }
            )}
            <div ref={listRef}></div>
          </MissionDetailsListStyled>
        )}
        <MissionDetailsItemStyled>
          <DropdownComponent
            placeholderValue="Select a Stop"
            listData={stops}
            currentItem={currentStopItem?.name ?? ""}
            changeItemAction={setCurrentStopItem}
          />
          <StopsDropdownStyled ref={dropdownRef}>
            <StopsDropdownCurrentStyled
              isOpen={stopTimeMenuOpened}
              onClick={() => setStopTimeMenuOpened(!stopTimeMenuOpened)}
            >
              {infinitelyStop ? "Manual Release" : `${stopSeconds} sec`}
            </StopsDropdownCurrentStyled>
            {stopTimeMenuOpened && (
              <StopsDropdownContentStyled>
                <InputStyled
                  type="number"
                  min="1"
                  placeholder="sec"
                  value={stopSeconds}
                  onChange={(e) => {
                    setStopSeconds(e.target.value);
                    setInfinitelyStop(false);
                  }}
                  notFocusedItem={true}
                  style={{
                    marginRight: "0",
                    marginBottom: "8px",
                    height: "34px",
                    padding: "10px 4px",
                  }}
                />
                <CheckboxComponent
                  checked={infinitelyStop}
                  onChange={(event) => {
                    setInfinitelyStop(event.target.checked);
                    setStopSeconds("");
                  }}
                  labelText="Manual Release"
                />
              </StopsDropdownContentStyled>
            )}
          </StopsDropdownStyled>
          {isUnhitchFeatureOn && (
            <CheckboxBodyStyled>
              <CheckboxComponent
                checked={isUnhitch}
                onChange={(event) => {
                  setHitchType(event.target.checked);
                }}
                labelText="Unhitch"
              />
            </CheckboxBodyStyled>
          )}
          <ButtonComponent
            clickAction={addPlanItem}
            type={disabledAddPlanBtn ? "disabled" : "add"}
            width={70}
            fontSize={14}
            marginBottom={0}
          >
            Add
          </ButtonComponent>
        </MissionDetailsItemStyled>
      </MissionDetailsItemContentStyled>
      <ButtonsContainerStyled>
        <ButtonComponent
          clickAction={editedMissionPlan ? editMissionAction : openModalAction}
          type={"primary"}
          marginBottom={0}
          posCenter={true}
          fontSize={14}
        >
          {editedMissionPlan ? "Save Changes" : "Create Mission"}
        </ButtonComponent>
        <ButtonComponent
          clickAction={() => {
            missionDeploymentActionsLogger.debug(
              `cancel mission ${editedMissionPlan ? "editing" : "creation"}`
            );
            cancelAction();
          }}
          type="cancel"
          posCenter={true}
          fontSize={14}
          marginBottom={0}
        >
          Cancel
        </ButtonComponent>
      </ButtonsContainerStyled>
      {errorMessage && <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>}
    </MissionDetailsContainerStyled>
  );
};
