import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FMSLogger } from "FMSLogger";
import { LIVE_MISSIONS_ACTIONS } from "Utils/constants";
import { cancelLiveAndQueueMission } from "Slices/missionsPage";
import { getVehicles } from "Slices/vehicles";
import { getLiveMissionsData } from "Slices/missionsPage/selectors";
import { List } from "Components/NewList";
import { getMissionStatusField } from "Components/MissionPlanner/components/MissionPlannerLists/utils";
import { getLoopsField, getStopsField } from "Components/common/TableElements";
import { MissionsPageSearchContext } from "Components/MissionsPage/SearchContextHelper";
import {
  getAssignedToField,
  getMissionNameField,
  parseTime,
} from "Components/LiveMissionsTable/utils";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { toggleModal } from "Slices/modals";
import { OverrideMissionWarning } from "./OverrideMissionWarning";

import { LiveMissionsWrapperStyled } from "./styles";

const liveMissionsActionsLogger = FMSLogger.byPrefix(LIVE_MISSIONS_ACTIONS);

export const LiveMissionsTable = memo(() => {
  const dispatch = useDispatch();

  const vehicles = useSelector(getVehicles);
  const vehicleTypes = useSelector(getVehicleTypes);
  const liveMissions = useSelector(getLiveMissionsData) as LiveMission[];

  const { searchValue } = useContext(MissionsPageSearchContext);

  const [overrideMissionWarningOptions, setOverrideMissionWarningOptions] =
    useState<{
      isShown: boolean;
      missionId: string | null;
      vehicleName: string | null;
    }>({ isShown: false, missionId: null, vehicleName: null });

  const optionsDropdownOffset = useMemo(() => ({ top: 40, right: 0 }), []);

  const options = useMemo(
    () => [
      {
        name: "Cancel",
        callback: (item: any) => {
          liveMissionsActionsLogger.debug(
            `Pressed cancel live mission with id:${item.id}`
          );
          dispatch(
            cancelLiveAndQueueMission({
              missionId: item.id,
              vehicleId: item.vehicleId,
            })
          );
        },
      },
      {
        name: "Override",
        callback: (item: any) => {
          const vehicle = vehicles.find(
            (vehicle) => vehicle?.id === item.vehicleId
          );
          liveMissionsActionsLogger.debug(
            `Pressed override live mission with id:${item.id}`
          );
          setOverrideMissionWarningOptions({
            isShown: true,
            missionId: item.id || null,
            vehicleName: vehicle?.name || null,
          });
        },
      },
    ],
    [vehicles]
  );

  const headerData = useMemo(
    () => ({
      name: "Mission Name",
      assignedTo: "Vehicle Assigned",
      status: "Status",
      stops: "Stops",
      loops: "Cycles",
      deployedAt: "Deployed at",
      startedExecutionAt: "Started Execution at",
    }),
    []
  );

  const handleMissionNameClick = useCallback((missionId: string) => {
    dispatch(
      toggleModal({
        type: "MISSION_DETAILS_MODAL",
        data: { missionId },
      })
    );
  }, []);

  const preparedLiveMissionsData = useMemo(() => {
    liveMissionsActionsLogger.debug("Recalculate preparedLiveMissionsData");
    if (!liveMissions) return [];
    const preparedLiveMissions = liveMissions.map((liveMission) => ({
      id: liveMission.id,
      name: getMissionNameField(
        liveMission.name,
        liveMission.id,
        handleMissionNameClick
      ),
      rawName: liveMission.name,
      assignedTo: getAssignedToField(
        liveMission.vehicle,
        vehicles,
        vehicleTypes
      ),
      status: getMissionStatusField(liveMission.status),
      stops: getStopsField(liveMission.passedStops, liveMission.totalStops),
      loops: getLoopsField(liveMission.passedLoops, liveMission.totalLoops),
      vehicleId: liveMission.vehicle,
      deployedAt: parseTime(liveMission.deployedAt),
      startedExecutionAt: parseTime(liveMission.startedExecutionAt),
    }));

    if (!searchValue) return preparedLiveMissions;

    return preparedLiveMissions.filter((liveMission) =>
      liveMission.rawName?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [liveMissions, vehicles, vehicleTypes, searchValue]);

  const handleCloseOverrideMissionWarningAction = useCallback(() => {
    setOverrideMissionWarningOptions({
      isShown: false,
      missionId: null,
      vehicleName: null,
    });
  }, []);

  const handleSuccessOverrideMissionWarningAction = useCallback(
    (missionId: string) => {
      console.log("TODO integrate override mission functionality", missionId);
      setOverrideMissionWarningOptions({
        isShown: false,
        missionId: null,
        vehicleName: null,
      });
    },
    []
  );

  return (
    <LiveMissionsWrapperStyled>
      <List
        data={preparedLiveMissionsData}
        headerData={headerData}
        options={options}
        optionsDropdownOffset={optionsDropdownOffset}
      />
      {overrideMissionWarningOptions.isShown && (
        <OverrideMissionWarning
          missionId={overrideMissionWarningOptions.missionId}
          vehicleName={overrideMissionWarningOptions.vehicleName}
          handleCloseAction={handleCloseOverrideMissionWarningAction}
          handleSuccessAction={handleSuccessOverrideMissionWarningAction}
        />
      )}
    </LiveMissionsWrapperStyled>
  );
});
